
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import './slides.css'
import './HeroSection.css';
import './card.css'
import logo from './logowhite.png';
import remove from './remove-location_12557391.png'
import wifi from './wifi-signal_3178314.png'
import chat from './chat-bubble_1048123.png'
import About from './E7E6162E-1C97-466B-B6E4-9C4334401A03_1_201_a.jpeg'

function chunkArray(array, chunkSize) {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
}

function Home_page() {
  const [buildings, setBuildings] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");  // Default to "All Cities"
  const [apartmentType, setApartmentType] = useState(""); // Default to "All Types"

  const displayedBuildings = buildings.slice(0, 3);
  useEffect(() => {
    fetch(`https://backendjaes.passiontrials.co.za/displayapartment`)  // Endpoint to fetch all apartments
      .then(response => response.json())
      .then(data => {
        //  setBuildings(data); // Assume the data is an array of apartments
      })
      .catch(error => console.error("Error loading all buildings:", error));

    buildingInfo();
  }, []);

  const buildingInfo = () => {
    fetch(`https://backendjaes.passiontrials.co.za/new_building_tb`)
      .then(res => res.json())
      .then(info => {
        setBuildings(info);
        console.log('this is the data that must be revealed', info);
      })
      .catch(error => {
        console.log('An error occurred', error);
      })
  };

  const handleGender = (binary) => {
    if (binary) {
      if (binary == 0) {
        return 'Female';
      }

      if (binary == 1) {

        return 'Male';
      }

      if (binary == 2) {
        return 'Female | Male';
      }
    }
  }
  return (
    <body>
     <section className="slider_container">
    <section className="slider">
      {/* Slide 1 with Image */}
      <div className="slide one">
        <img src="https://jaes.co.za/wp-content/uploads/2024/01/IMG_20230226_113005-scaled.jpg" alt="Luxury Living" />

        {/* Text overlay content */}
        <div className="whispering-dreams">
          <p className="echo-of-the-past">Jae's Living Experience</p>
          <h1 className="celestial-symphony">Move in today!</h1>
        </div>

        {/* Button on the right side */}
        <div className="twilight-portal">
          <Link to="/Building">
            <button className="stardust-voyage">View Property →</button>
          </Link>
        </div>
      </div>

      {/* Slide 2 */}
      <div className="slide two">
        <img src="https://jaes.co.za/wp-content/uploads/2024/01/IMG_2865-scaled.jpg" alt="Living Experience" />

        {/* Text overlay content */}
        <div className="whispering-dreams">
          <p className="echo-of-the-past">Jae's Living Experience</p>
          <h1 className="celestial-symphony">Move in today!</h1>
        </div>

        {/* Button on the right side */}
        <div className="twilight-portal">
          <Link to="/Building">
            <button className="stardust-voyage">View Property →</button>
          </Link>
        </div>
      </div>

      {/* Slide 3 */}
      <div className="slide three">
        <img src="https://jaes.co.za/wp-content/uploads/2024/01/IMG_20230226_113005-scaled.jpg" alt="Living Experience" />

        {/* Text overlay content */}
        <div className="whispering-dreams">
          <p className="echo-of-the-past">Jae's Living Experience</p>
          <h1 className="celestial-symphony">Move in today!</h1>
        </div>

        {/* Button on the right side */}
        <div className="twilight-portal">
          <Link to="/Building">
            <button className="stardust-voyage">View Property →</button>
          </Link>
        </div>
      </div>

      {/* Slide 4 */}
      <div className="slide four">
        <img src="https://jaes.co.za/wp-content/uploads/2024/01/IMG_2865-scaled.jpg" alt="Living Experience" />

        {/* Text overlay content */}
        <div className="whispering-dreams">
          <p className="echo-of-the-past">Jae's Living Experience</p>
          <h1 className="celestial-symphony">Move in today!</h1>
        </div>

        {/* Button on the right side */}
        <div className="twilight-portal">
          <Link to="/Building">
            <button className="stardust-voyage">View Property →</button>
          </Link>
        </div>
      </div>
    </section>
  </section>

      <section class="w3l-aboutblock py-lg-5 py-4" id="about">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <img src="https://jaes.co.za/wp-content/uploads/2024/01/IMG_2865-scaled.jpg" alt="" class="img-fluid radius-image" />
            </div>

            <div class="col-lg-6 ps-xl-5 ps-lg-4 mt-lg-0 mt-5 text-start">
              <div class="title-main">
                <p>About Us</p>
                <h3 class="title-style">THE PERFECT NEW HOME</h3>
              </div>
              <p class="mt-3">Step into a world where students connect with welcoming homes, supportive landlords, and trusted academic partners.
                 Our platform is designed to make finding a safe and comfortable place to live easy and stress-free, 
                 so you can focus on what matters most—your studies and student life.</p>
              <ul class="mt-4 list-style-lis">
              <li><i class="fas fa-check-circle"></i>120+ Verified Listings</li>
                <li class="mt-2"><i class="fas fa-check-circle"></i>100% Satisfaction Guarantee</li>
                <li class="mt-2"><i class="fas fa-check-circle"></i>24+ Years of Experience</li>
                <li class="mt-2"><i class="fas fa-check-circle"></i>Over 10 Years of Experience You Can Trust</li>
              </ul>
              <a href="/Building" class="btn btn-style mt-5">Booking Now</a>
            </div>
          </div>
        </div>
      </section>

      <section class="w3l-index5 pb-5 pt-4">
        <div class="container-fluid py-lg-5 py-md-4 py-2">
          <div class="title-main text-center mx-auto mb-md-5 mb-4" style={{ maxWidth: '600px' }}>
            <p>Featured tours</p>
            <h3 class="title-style">Most Popular Tours</h3>
          </div>
          <div className="building-gridHome">
            {displayedBuildings.map((build) => (
              <Link to={`/Apartments/${build.BuildingId}`} className="view-link" key={build.BuildingId}>
                <div className="room-cardHome">
                  <img src={`https://backendjaes.passiontrials.co.za/src${build.BuildingInteriorImage1}`} alt="Building Exterior" className="room-card-imageHome" />
                  <div className="room-card-content">
                    <h2 className="room-title">{build.BuildingName}</h2>
                    <p className="b-type"><i className="fas fa-map-marker-alt me-1"></i>{build.BuildingLocation}</p>
                    <div className="plan-options">
                      <button className="plan-option">{handleGender(build.PreferedGender)}</button>
                    </div>
                    <div className="room-details">
                      <div className="detail-item">
                        <p>From R4000 per room</p>
                        <span>8 Beds Avalaible </span>
                      </div> {/*
                      <div className="detail-item">
                        <p>Zone</p>
                        <span>{build.Zone}</span>
                      </div> 
                      <div className="detail-item">
                        <p>Sleeps</p>
                        <span>{build.Capacity}</span>
                      </div>*/}
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
          {/* Add button to navigate to all buildings */}
          <div className="text-center mt-4">
            <Link to="/Building">
              <button className="btn btn-style">View All Buildings</button>
            </Link>
          </div>
        </div>
      </section>
      <section class="w3_stats py-5" id="stats"><div class="title-main text-center mx-auto mb-5" style={{ maxWidth: '600px' }}>
            <h3 class="title-style text-white">We have over 10 years Experience</h3>
          </div>
        <div class="container py-lg-5 py-md-4 py-2">
          
          <div class="w3-stats text-center py-lg-4">
            <div class="row">
              <div class="col-lg-4 col-md-6 col-sm-10 mt-md-0 mt-4">
                <div class="counter">
                  <i class="fas fa-route"></i>
                  <div class="d-flex align-items-center justify-content-center">
                    <div class="timer" data-to="200" data-speed="1500"></div>
                  </div>
                  <p class="count-text">Reliable Transportation services</p>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-10 mt-md-0 mt-4">
                <div class="counter">
                  <i class="fas fa-smile"></i>
                  <div class="d-flex align-items-center justify-content-center">
                    <div class="timer" data-to="100" data-speed="1500"></div>
                
                  </div>
                  <p class="count-text">Safety Assured Accommodations</p>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-10 mt-md-0 mt-4">
                <div class="counter">
                  <i class="fas fa-umbrella-beach"></i>
                  <div class="d-flex align-items-center justify-content-center">
                    <div class="timer" data-to="130" data-speed="1500"></div>
                  </div>
                  <p class="count-text">Consistently Beautiful Facilities </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="w3l-grids-block py-5"> <div class="title-main mb-4" style={{ maxWidth: '600px', margin: '0 auto', textAlign: 'center' }}>
  <p>Our Services</p>
  <h3 class="title-style">Why Stay at Jaes</h3>
</div>

  <div class="container py-lg-5 py-md-4 py-2">
   
 

    
    <div class="row text-center justify-content-center">
      <div class="col-lg-4 col-md-6 col-sm-10">
        <div class="bottom-block smaller-items">
          <a href="tours.html" class="d-block">
            <img class="bottom-block smaller-items" style={{ width: '50%' }} src={remove} alt="Card image cap" />
            <h3 class="my-3">Safety & Comfort</h3>
            <p class="">Our homes combine safety and beauty, providing a secure and comfortable living environment.</p>
          </a>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-10 mt-md-0 mt-4">
        <div class="bottom-block smaller-items">
          <a href="tours.html" class="d-block">
            <img class="bottom-block smaller-items" style={{ width: '50%' }} src={wifi} alt="Card image cap" />
            <h3 class="my-3">Stay Connected</h3>
            <p class="">Our homes come equipped with fast, reliable Wi-Fi to keep you connected.</p>
          </a>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-10 mt-lg-0 mt-4">
        <div class="bottom-block smaller-items">
          <a href="tours.html" class="d-block">
            <img class="bottom-block smaller-items" style={{ width: '50%' }} src={chat} alt="Card image cap" />
            <h3 class="my-3">Seamless Updates</h3>
            <p class="">Owners provide quick, consistent updates, ensuring you're always informed and communication remains hassle-free.</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>


      <section class="w3l-whyblock pb-5 pt-2">
        <div class="container pb-lg-5 pb-md-4 pb-2">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <img  src={About} alt="" class="img-fluid radius-image" />
            </div>
            <div class="col-lg-6 ps-xl-5 ps-lg-4 mt-lg-0 mt-5">
              <div class="title-main mb-4" style={{ maxWidth: '600px' }}>
                <p>Our Features</p>
                <h3 class="title-style">Why Pick Us !</h3>
              </div>
              <p> Backed by years of trust and a wide range of verified listings, we ensure hassle-free living and transportation. Simplify your student life with a platform designed for your peace of mind!</p>
              <div class="two-grids mt-5">
                <div class="grids_info">
                  <i class="fas fa-certificate"></i>
                  <div class="detail">
                    <h4> 2025 NSFAS Approved accommodations</h4>
                    <p>Find your ideal student home with NSFAS Approved Accommodations today.</p>
                  </div>
                </div>
                <div class="grids_info mt-xl-5 mt-4">
                  <i class="fas fa-money-bill-wave"></i>
                  <div class="detail">
                    <h4>Book student accommodation instantly</h4>
                    <p>Instant booking for a seamless experience.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div class="w3l-blog-content py-5">
        <div class="container py-lg-5 py-md-4 py-2">
          <div class="title-main text-center mx-auto mb-md-5 mb-4" style={{ maxWidth: '600px' }}>
            <p>Our Blog</p>
            <h3 class="title-style">Learn more from our latest Blog Posts</h3>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
              <div class="blog-grid-1">
                <div class="card-header p-0 position-relative">
                  <a href="#blog" class="zoom d-block">
                    <img class="card-img-bottom d-block" src="assets/images/blog1.jpg" alt="Card image cap" />
                  </a>
                  <div class="course-price-badge">Trips</div>
                </div>
                <div class="card-body course-details">
                  <div class="course-meta mb-3">
                    <div class="meta-item course-students">
                      <a href="#author"><span class="fas fa-user"></span>
                        <span class="meta-value"></span>John</a>
                    </div>
                    <div class="meta-item course-lesson">
                      <span class="fa fa-heart"></span>
                      <span class="meta-value"> 23 </span>
                    </div>
                    <div class="meta-item course-students">
                      <span class="fa fa-calendar"></span>
                      <span class="meta-value">Dec 06, 2021</span>
                    </div>
                  </div>
                  <a href="#blog" class="course-desc">Travel the Most Beautiful Places in the
                    World</a>
                  <p class="mt-2">Lorem ipsum dolor sit, amet consectetur elit. Earum mollitia
                    ipsam autem.</p>
                  <a href="#blog" class="btn btn-style mt-4">Read more</a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-md-0 mt-4">
              <div class="blog-grid-1">
                <div class="card-header p-0 position-relative">
                  <a href="#blog" class="zoom d-block">
                    <img class="card-img-bottom d-block" src="assets/images/blog2.jpg" alt="Card image cap" />
                  </a>
                  <div class="course-price-badge">Travels</div>
                </div>
                <div class="card-body course-details">
                  <div class="course-meta mb-3">
                    <div class="meta-item course-students">
                      <a href="#author"><span class="fas fa-user"></span>
                        <span class="meta-value"></span>Anton</a>
                    </div>
                    <div class="meta-item course-lesson">
                      <span class="fa fa-heart"></span>
                      <span class="meta-value"> 24 </span>
                    </div>
                    <div class="meta-item course-students">
                      <span class="fa fa-calendar"></span>
                      <span class="meta-value">Dec 07, 2021</span>
                    </div>
                  </div>
                  <a href="#blog" class="course-desc">A Place where Start New Life with Peace</a>
                  <p class="mt-2">Lorem ipsum dolor sit, amet consectetur elit. Earum mollitia
                    ipsam autem.</p>
                  <a href="#blog" class="btn btn-style mt-4">Read more</a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-lg-0 mt-4">
              <div class="blog-grid-1">
                <div class="card-header p-0 position-relative">
                  <a href="#blog" class="zoom d-block">
                    <img class="card-img-bottom d-block" src="assets/images/blog3.jpg" alt="Card image cap" />
                  </a>
                  <div class="course-price-badge">Journey</div>
                </div>
                <div class="card-body course-details">
                  <div class="course-meta mb-3">
                    <div class="meta-item course-students">
                      <a href="#author"><span class="fas fa-user"></span>
                        <span class="meta-value"></span>Miche</a>
                    </div>
                    <div class="meta-item course-lesson">
                      <span class="fa fa-heart"></span>
                      <span class="meta-value"> 22 </span>
                    </div>
                    <div class="meta-item course-students">
                      <span class="fa fa-calendar"></span>
                      <span class="meta-value">Dec 08, 2021</span>
                    </div>
                  </div>
                  <a href="#blog" class="course-desc">Top 10 Destinations & Adventure Trips</a>
                  <p class="mt-2">Lorem ipsum dolor sit, amet consectetur elit. Earum mollitia
                    ipsam autem.</p>
                  <a href="#blog" class="btn btn-style mt-4">Read more</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <section class="w3l-promocode py-5">
        <div class="container ppy-lg-5 py-md-4 py-2">
          <div class="row aap-4-section align-items-center">
            <div class="col-lg-6 col-8 m-auto app4-right-image pe-md-5 text-center">
              <img src="assets/images/img.jpg" class="img-fluid radius-image" alt="App Device" />
            </div>
            <div class="col-lg-6 app4-left-text ps-lg-0 mb-lg-0 mb-5">
              <h6>For 30% Discount</h6>
              <h4>Get Our Promocode</h4>
              <p class="mb-4"> Uspendisse efficitur orci urna. In et augue ornare, tempor massa in, luctus
                sapien. Proin a
                diam et dui fermentum molestie vel id neque. </p>
              <div class="app-4-connection">
                <div class="newsletter">
                  <label>Never Miss a Deal !</label>
                  <form action="#" methos="GET" class="d-flex wrap-align">
                    <input type="email" placeholder="Enter your email id" required="required" />
                    <button type="submit" class="button-style">Get Promocode</button>
                  </form>
                </div>
                <p class="mobile-text-app mt-4 pt-2">(Or) To Get Our Mobile Apps</p>
                <div class="app-4-icon">
                  <ul>
                    <li><a href="#url" title="Apple" class="app-icon apple-vv"><span
                      class="fab fa-apple  icon-color" aria-hidden="true"></span></a></li>
                    <li><a href="#url" title="Google play" class="app-icon play-vv"><span
                      class="fab fa-google-play icon-color" aria-hidden="true"></span></a>
                    </li>
                    <li><a href="#url" title="Microsoft" class="app-icon windows-vv"><span
                      class="fab fa-windows icon-color" aria-hidden="true"></span></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <footer class="w3l-footer-29-main py-5">
        <div class="container pt-lg-5 pt-sm-4 pt-2 pb-2">
          <div class="row footer-top-29">
          <div className="logo">
                    <Link className="navbar-brand" to="/">
                        <img style={{width:"100%"}} src={logo} alt="Tours Logo" />
                    </Link>
            </div>
           
            <div class="col-lg-2 col-md-3 col-6 ps-lg-5 ps-lg-4 footer-list-29 mt-md-0 mt-4">
              <ul>
                <h6 class="footer-title-29">Quick Links</h6>
                <li><a href="about.html">About Us</a></li>
                <li><a href="#services">Services</a></li>
                <li><a href="contact.html">Contact Us</a></li>
                <li><a href="#rewards">Rewards</a></li>
              </ul>
            </div>
            <div class="col-lg-2 col-md-3 col-6 footer-list-29 mt-md-0 mt-4">
              <ul>
                <h6 class="footer-title-29">Explore</h6>
                <li><a href="#blog">Blog Posts</a></li>
                <li><a href="#privacy">Privacy policy</a></li>
                <li><a href="#partners">Our Partners</a></li>
                <li><a href="#events">Events</a></li>
              </ul>
            </div>
            <div class="col-lg-4 col-md-6 footer-list-29">
              <h6 class="footer-title-29">Contact Info </h6>
              <p class="mb-2">Phone Number : <a href="tel:+27(81) 500 6508">+27(81) 500 6508</a></p>
              <p class="mb-2">Email : <a href="Info@jaes.co.za">Info@jaes.co.za</a></p>
              <div class="mt-4">
                <ul class="social">
                  <li><a href="https://web.whatsapp.com/send?phone=27815006508&text=Hi,%20I%20would%20like%20to%20enquire%20about%20booking."><i class="fab fa-whatsapp"></i></a>
                  </li>
                  <li><a href="https://www.tiktok.com/@jaesproperties?_t=8plUlPz0Xw5&_r=1"><i class="fab fa-tiktok"></i></a>
                  </li>
                  <li><a href="https://www.instagram.com/jaesproperties?igsh=MTYxbW52eWd3ejVhcQ=="><i class="fab fa-instagram"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <p class="copy-footer-29 text-center mt-5 pt-lg-3">© 2024 Jae's Homes. All rights reserved. Design
            by <a href="https://vingamedia.co.za" target="_blank">VingaMedia</a></p> */}
        </div>
      </footer>
    </body>
  );
}
export default Home_page;
