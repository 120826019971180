import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from './AuthProvider';
import './navBar.css';
import './assets/css/style-starter.css';
import logo from './logoblack.png';
import user from './userdefault.png';

function Navbar() {
    const { isLoggedIn, logout, role, userId } = useAuth();
    const navigate = useNavigate();
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [apartment, setApartment] = useState({});
    const dropdownRef = useRef(null); // To reference the dropdown menu

    useEffect(() => {
        if (isLoggedIn) {
            fetch(`https://backendjaes.passiontrials.co.za/user_full_details/${userId}`, {
                headers: { "Content-Type": "application/json" }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to fetch user details');
                    }
                    return response.json();
                })
                .then(data => {
                    if (data && data[0]) {
                        setApartment(data[0]);
                    } else {
                        console.log('No user full details found');
                    }
                })
                .catch(err => {
                    console.error('Error fetching user details:', err);
                });
        } else {
            setApartment({}); // Reset apartment data when logged out
        }
    }, [userId, isLoggedIn]);

    const handleLogout = () => {
        logout();
        navigate('/');
    };

    const handleLogin = () => {
        navigate("/login");
    };

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    // Close the dropdown when clicking outside
    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownVisible(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    const isAdminRole = role === 'Admin' || role === 'Super Admin';

    // Generate initials from user name
    const getInitials = (name) => {
        if (!name) return '';
        const nameParts = name.split(' ');
        const initials = nameParts.map(part => part[0]).join('');
        return initials.toUpperCase();
    };

    // Render profile image or initials on background
    const renderProfileImage = () => {
        if (isLoggedIn && apartment.FaceImage) {
            const initials = getInitials(apartment.Name);
            return (
                <div style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    backgroundColor: '#ff5a5f',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#fff',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    textTransform: 'uppercase'
                }}>
                    {initials || 'J'}
                </div>
            );

        } else {
            return (

                <img
                    src={user}
                    alt="Profile Icon"
                />
            );
        }
    };

    return (
        <>
            <header id="airbnb-header">
                <div className="logo">
                    <Link className="navbar-brand" to="/">
                        <img src={logo} alt="Tours Logo" />
                    </Link>
                </div>
                {/* <nav>
                    <ul>
                        <li>
                            <a href="#">JAES INVESTMENTS</a>
                        </li>
                        <li>
                            <a href="#">JAES CONSULTING</a>
                        </li>
                    </ul>
                </nav> */}
                <div className="right-menu">
                    <a style={{ opacity: '0' }}>Jaes your home</a>
                    <div className="language-selector">
                        <a href='https://jaes.co.za/'> <i className="fas fa-globe"></i></a>
                    </div>
                    <div className="menu-profile" onClick={toggleDropdown}>
                        <div className="hamburger-menu">
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                        </div>
                        <div className="profile-icon">
                            {renderProfileImage()}
                        </div>
                    </div>
                </div>

                {dropdownVisible && (
                    <div id="dropdown-menu" ref={dropdownRef} className={`dropdown-menu ${dropdownVisible ? 'show' : ''}`}>
                        {isLoggedIn && role === "User" && (
                            <a style={{ paddingLeft: '15px', paddingTop: '10px' }}>
                                <Link className="nav-link" to="/userDashboard">
                                    Student portal
                                </Link>
                            </a>
                        )}
                        {isLoggedIn && isAdminRole && (
                            <a style={{ paddingLeft: '15px', paddingTop: '10px' }}>
                                <Link className="nav-link" to="/adminDashboard">
                                    Admin Dashboard
                                </Link>
                            </a>
                        )}
                        {isLoggedIn ? (
                            <a style={{ paddingLeft: '15px', paddingTop: '10px', cursor: "pointer" }} onClick={handleLogout}>
                                Logout
                            </a>
                        ) : (
                            <a style={{ paddingLeft: '15px', paddingTop: '10px', cursor: "pointer" }} className={` ${dropdownVisible ? 'show' : ''}`} onClick={handleLogin}>
                                Login
                            </a>
                        )}
                        <hr style={{ marginTop: '10px', fontWeight: '700' }} />
                        <a style={{ paddingLeft: '15px', paddingTop: '10px' }}>Jaes your home</a>
                        <a  href="/ContactUs" style={{ paddingLeft: '15px', paddingTop: '10px', marginBottom: '10px' }}>Contact us</a>
                        <a style={{ paddingLeft: '15px', paddingTop: '10px', marginBottom: '10px' }}>Help Center</a>
                    </div>
                )}
            </header>
        </>
    );
}

export default Navbar;
