import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import './slides.css';
import './building.css';
import './filterStyles.css';

function chunkArray(array, chunkSize) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
    }
    return result;
}

function Building() {
    const [buildings, setBuildings] = useState([]);
    const [zone, setZone] = useState(""); // Default to no zone filter
    const [gender, setGender] = useState(""); // Default to no gender filter
    const sliderRef = useRef(null); // Ref to track the slider div

    const buildingRows = chunkArray(buildings, 3);

    useEffect(() => {
        buildingInfo();
        window.scrollTo(0, 0);
    }, []);

    const buildingInfo = () => {
        fetch(`https://backendjaes.passiontrials.co.za/new_building_tb`)
            .then(res => res.json())
            .then(info => {
                setBuildings(info);
                console.log('this is the data that must be revealed', info);
            })
            .catch(error => {
                console.log('An error occurred', error);
            });
    };

    const handleGender = (binary) => {
        if (binary == 0) return 'Female';
        if (binary == 1) return 'Male';
        if (binary == 2) return 'Female | Male';
    };

    const handleFilterChange = () => {
        // Apply the filters based on zone, gender
        fetch(`https://backendjaes.passiontrials.co.za/new_building_tb?zone=${zone}&gender=${gender}`)
            .then(res => res.json())
            .then(filteredBuildings => {
                setBuildings(filteredBuildings);
            })
            .catch(error => console.log('Error filtering buildings:', error));
    };

    const handleNext = () => {
        if (sliderRef.current) {
            sliderRef.current.scrollLeft += sliderRef.current.offsetWidth;
        }
    };

    const handlePrev = () => {
        if (sliderRef.current) {
            sliderRef.current.scrollLeft -= sliderRef.current.offsetWidth;
        }
    };

    return (
        <body>
            {/* Filter Section */}
            <div class="container py-lg-5 py-md-4 py-2 d-flex justify-content-center">
                <div class="d-flex align-items-center justify-content-center">
                    <div class="me-3">
                        <select
                            id="zoneFilter"
                            class="form-control"
                            value={zone}
                            onChange={(e) => setZone(e.target.value)}
                            style={{
                                width: '200px',
                                height: '48px',
                                borderRadius: '39px',
                                backgroundColor: '#ff5a5f',
                                color: 'white',
                                border: 'none'
                            }}
                        >
                            <option value="">All Zones</option>
                            <option value="1">Westridge, Manor Gardens Area</option>
                            <option value="2">Glenwood Area</option>
                            <option value="3">Umbilo, Lower Glenwood Area</option>
                            <option value="4">Glenmore, Carrington Heights Area</option>
                        </select>
                    </div>

                    <div class="me-3">
                        <select
                            id="genderFilter"
                            class="form-control"
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            style={{
                                width: '200px',
                                height: '48px',
                                borderRadius: '39px',
                                backgroundColor: '#ff5a5f',
                                color: 'white',
                                border: 'none'
                            }}
                        >
                            <option value="">All Genders</option>
                            <option value="0">Female</option>
                            <option value="1">Male</option>
                            <option value="2">Female & Male</option>
                        </select>
                    </div>

                    <div>
                        <button
                            class="btn btn-primary"
                            onClick={handleFilterChange}
                            style={{
                                width: '200px',
                                height: '48px',
                                borderRadius: '39px',
                                backgroundColor: '#ff5a5f',
                                color: 'white',
                                border: 'none'
                            }}
                        >
                            Apply Filters
                        </button>
                    </div>
                </div>
            </div>

            {/* Building Listings in Scroll */}
            <div className="dsasdssa" id="dsasdssa" ref={sliderRef}>
                {buildings.map((build) => (
                    <div className="dsasdssa-item" key={build.BuildingId}>
                        <div className="room-card">
                            <img
                                src={`https://backendjaes.passiontrials.co.za/src${build.BuildingInteriorImage1}`}
                                alt="Building Exterior"
                                className="room-card-image"
                            />
                            <div className="room-card-content">
                                <h2 className="room-title">{build.BuildingName}</h2>
                                <p className="b-type">
                                    <i className="fas fa-map-marker-alt me-1"></i>
                                    {build.BuildingLocation}
                                </p>
                                <div className="plan-options">
                                    <button className="plan-option">{handleGender(build.PreferedGender)}</button>
                                </div>
                                <Link to={`/Apartments/${build.BuildingId}`} className="view-link">
                                    <i className="fas fa-bed me-1"></i>{build.Setup}
                                </Link>
                                <div className="room-details">
                                    <div className="detail-item">
                                        <p>From R4000 per room</p>
                                        <span>{build.Capacity > 0 ? `${build.Capacity} Beds Available` : 'No Beds Available'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/* Prev and Next buttons */}
            <div className="fdsdsfdsdsv">
                <button className="dsdss" onClick={handlePrev}>&#10094; Prev</button>
                <button className="dsdss" onClick={handleNext}>Next &#10095;</button>
            </div>

            {/* Footer */}
            <footer class="w3l-footer-29-main py-5">
                <div class="container pt-lg-5 pt-sm-4 pt-2 pb-2">
                    {/* Footer content */}
                    <p class="copy-footer-29 text-center mt-5 pt-lg-3">© 2021 Tours. All rights reserved. Design
                        by <a href="https://w3layouts.com/" target="_blank">W3Layouts</a></p>
                </div>
            </footer>
        </body>
    );
}

export default Building;
