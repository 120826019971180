// import React, { useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
// import { useAuth } from './AuthProvider';

// function PaymentProcess() {
//     const location = useLocation();
//     const { date, apartment, apartmentandbuilding } = location.state || {};
//     const nextPaymentDate = new Date(date);
//     nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 1);

//     const { userId } = useAuth();

//     useEffect(() => {
//         if (!date || !apartment, !apartmentandbuilding) {
//             return;
//         }


//         fetch("https://backendjaes.passiontrials.co.za/user_apartment_tb", {
//             method: "POST", headers: { "Content-Type": "application/json" },
//             body: JSON.stringify({
//                 UserId: userId,
//                 ApartmentId: apartment.ApartmentId,
//                 MoveInDate: date.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' }),
//                 MoveOutDate: nextPaymentDate.toLocaleDateString('en-US'),
//                 RentAmount: apartment.ApartmentPrice + 200 + 300,
//                 LeaseTerm: "1 Month",
//                 IsActive: true,
//                 DepositPaid: true,
//                 ApartmentRating: apartment.ApartmentRating || 5,
//                 Guest: apartment.Guest,
//                 Notes: "no specific notes",
//                 TenantAgreementType: "Month-to-Month"
//             })
//         })
//             .then(response => {
//                 if (!response.ok) {
//                     throw new Error("   Failed to book apartment");
//                 }
//                 return response.json();
//             })
//             .then(() => {

//             }).catch(error => {
//                 console.error("Error during apartment booking: ", error);

//             });


//         const form = document.createElement('form');
//         form.action = "https://sandbox.payfast.co.za/eng/process";
//         form.method = "post";

//         const merchantId = document.createElement("input");
//         merchantId.type = "hidden";
//         merchantId.name = "merchant_id";
//         merchantId.value = "10030988";
//         form.appendChild(merchantId);

//         const merchantKey = document.createElement("input");
//         merchantKey.type = "hidden";
//         merchantKey.name = "merchant_key";
//         merchantKey.value = "2h99wu1jddrdr";
//         form.appendChild(merchantKey);

//         const amount = document.createElement("input");
//         amount.type = "hidden";
//         amount.name = "amount";
//         amount.value = `${apartment.ApartmentPrice + 20 + 30}`;
//         form.appendChild(amount);

//         const itemName = document.createElement("input");
//         itemName.type = "hidden";
//         itemName.name = "item_name";
//         itemName.value = `${apartmentandbuilding.BuildingName} - ${apartment.ApartmentType}`;
//         form.appendChild(itemName);

//         const returnUrl = document.createElement("input");
//         returnUrl.type = "hidden";
//         returnUrl.name = "return_url";
//         returnUrl.value = "https://jaesstudenthomes.co.za/Payment";
//         form.appendChild(returnUrl);

//         const cancelUrl = document.createElement("input");
//         cancelUrl.type = "hidden";
//         cancelUrl.name = "cancel_url";
//         cancelUrl.value = "https://jaesstudenthomes.co.za/cancel";
//         form.appendChild(cancelUrl);

//         document.body.appendChild(form);
//         form.submit();







//     }, [date, apartment, apartmentandbuilding]);

//     if (!date || !apartment || !apartmentandbuilding) {
//         return <div>Loading...</div>;
//     }


//     return (
//         <div>
//             <h1>Processing Payment...</h1>
//         </div>
//     );

// }

// const bookApartment = async () => {
//     fetch("https://backendjaes.passiontrials.co.za/user_apartment_tb", {
//         method: "POST", headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({
//             UserId: userId,
//             ApartmentId: apartment.ApartmentId,
//             MoveInDate: date.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' }),
//             MoveOutDate: nextPaymentDate.toLocaleDateString('en-US'),
//             RentAmount: apartment.ApartmentPrice + 200 + 300,
//             LeaseTerm: "1 Month",
//             IsActive: true,
//             DepositPaid: true,
//             ApartmentRating: apartment.ApartmentRating || 5,
//             Guest: apartment.Guest,
//             Notes: "no specific notes",
//             TenantAgreementType: "Month-to-Month"
//         })
//     })
//         .then(response => {
//             if (!response.ok) {
//                 throw new Error("Failed to book apartment");
//             }
//             return response.json();
//         })
//         .then(() => {
//             // navigate('/Payment'); // If booking is successful, redirect to payment page
//         })
//         .catch(error => {
//             console.error("Error during apartment booking: ", error);
//         });
// };

// const paymentRecord = () => {
//     fetch("https://backendjaes.passiontrials.co.za/payment_tb", {
//         method: "POST", headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({
//             UserId: userId,
//             ApartmentId: apartment.ApartmentId,
//             PayDate: date.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' }),
//             PayNextDate: nextPaymentDate.toLocaleDateString('en-US'),
//             PayAmount: apartment.ApartmentPrice + 200 + 300,
//             PayNote: "Monthly rent",
//             PaymentStatus: "Paid",
//             PaymentType: "Rent",
//             PayAdvancePeriod: "15",
//             PayGracePeriod: "3",
//             PayMethod: "PayFast"

//         })
//     })
//         .then(response => {
//             if (!response.ok) {
//                 throw new Error("Failed to save payment information");
//             }
//             return response.json();
//         })
//         .then(() => {
//             navigate('/Payment'); // If booking is successful, redirect to payment page
//         })
//         .catch(error => {
//             console.error("Error during saving payment: ", error);
//         });
// };

// export default PaymentProcess;
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';

function PaymentProcess() {
    const location = useLocation();
    const navigate = useNavigate();
    const { date, apartment, apartmentandbuilding, totalAmount, serviceFee, chosenPlan, rentCost, nextPaymentDate } = location.state || {};
    let nextPaymentDate1 = new Date(date);
    // nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 1);
    nextPaymentDate1.setMonth(nextPaymentDate1.getMonth() + 1, 1);

    const { userId, loading: authLoading } = useAuth();
    const [readyToBook, setReadyToBook] = useState(false);
    const [error, setError] = useState('');

    let amount = apartment.ApartmentPrice;
    let currentDay = date.toLocaleDateString('en-US', { day: 'numeric' });
    let lateDate = 0;
    let earlyDate = 0;
    let AmountAfterDeduction = 0;
    let AmountAfterIncrease = 0;

    useEffect(() => {
        if (authLoading || !userId) return;
        if (!date || !apartment || !apartmentandbuilding || !totalAmount || !serviceFee || !chosenPlan || !rentCost) {
            console.warn("Missing data:", { userId, date, apartment, apartmentandbuilding, totalAmount, serviceFee, chosenPlan, rentCost });
            return;
        }

        const checkBooking = async () => {
            const response = await fetch(`https://backendjaes.passiontrials.co.za/check_booking/${userId}/${apartment.ApartmentId}`, {
                headers: { 'Content-Type': "application/json" }
            }
            );

            if (!response.ok) {
                alert('Failed to check booking status');
                return;
            }

            const data = await response.json();
            if (data.exists) {
                alert('You already have a booking for this apartment.');
                navigate(-1);
            } else if (!data.exists) {
                // setReadyToBook(true);
                checkApartmentAssociated();
            }


        };

        checkBooking();
    }, [userId, apartment, date, apartmentandbuilding, totalAmount, serviceFee, chosenPlan, rentCost, navigate, authLoading]);

    useEffect(() => {
        if (readyToBook) {
            bookApartment();
        }
    }, [readyToBook]);

    const checkApartmentAssociated = async () => {
        try {

            const response = await fetch(`https://backendjaes.passiontrials.co.za/check_apartment_if_associated/${apartment.ApartmentId}`, {
                headers: { 'Content-Type': 'application/json' }
            });

            if (!response.ok) {
                throw new Error('Failed to check apartment association');
            }
            const data = await response.json();

            if (data.exists) {
                alert('This apartment has already been booked by another user.');
                navigate(-1);
            }
            else {
                setReadyToBook(true);
            }

        }
        catch (err) {
            console.error('Error:', err);
            setError(err.message);
            alert('An error occurred while checking apartment associated,please try again later.');
        }
    };

    const bookApartment = async () => {
        try {

            amount = apartment.ApartmentPrice;
            //currentDay = date.toLocaleDateString('en-US', { day: 'numeric' });
            //  lateDate = 0;
            // earlyDate = 0;
            //  AmountAfterDeduction = 0;
            //  AmountAfterIncrease = 0;
            // if (currentDay > 1 && currentDay < 25) {

            //     currentDay = currentDay - 1;
            //     earlyDate = currentDay / 100 * amount;

            //     AmountAfterDeduction = amount - earlyDate;

            //     amount = AmountAfterDeduction;

            // }
            // else if (currentDay > 24 && currentDay <= 31) {
            //     currentDay = currentDay - 1;
            //     lateDate = currentDay / 100 * amount;

            //     AmountAfterIncrease = lateDate + amount;

            //     amount = AmountAfterIncrease;

            //     nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 1, 1);

            // }




            const bookingResponse = await fetch("https://backendjaes.passiontrials.co.za/user_apartment_tb", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    UserId: userId,
                    ApartmentId: apartment.ApartmentId,
                    MoveInDate: date.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' }),
                    MoveOutDate: nextPaymentDate.toLocaleDateString('en-US', { day: 'numeric', month: "long", year: "numeric" }),
                    RentAmount: totalAmount,   //changed
                    LeaseTerm: "1 Month",
                    IsActive: true,
                    DepositPaid: true,
                    ApartmentRating: apartment.ApartmentRating || 5,
                    Guest: apartment.Guest,
                    Notes: "no specific notes",
                    TenantAgreementType: "Month-to-Month",
                    ChosenPlan: chosenPlan,
                    ServiceFee: serviceFee,
                    RentCost: rentCost
                })
            });


            if (!bookingResponse.ok) {
                const errorText = await bookingResponse.text();
                throw new Error(`Failed to book apartment: ${errorText}`);
            }

            await paymentRecord(true);

            alert('Apartment booked successfully!');
            //  navigate('/Payment'); // Navigate to Payment page on successful booking
            submitPayFastForm();

        } catch (error) {
            console.error('Error during apartment booking:', error);
            alert(`Error during booking process: ${error.message}`);
        }
    };

    const formatAmount = (amount) => {
        return amount.toFixed(2);
    };

    const submitPayFastForm = () => {
        const form = document.createElement('form');
        form.action = "https://sandbox.payfast.co.za/eng/process";
        form.method = "post";

        const merchantId = document.createElement("input");
        merchantId.type = "hidden";
        merchantId.name = "merchant_id";
        merchantId.value = "10030988";
        form.appendChild(merchantId);

        const merchantKey = document.createElement("input");
        merchantKey.type = "hidden";
        merchantKey.name = "merchant_key";
        merchantKey.value = "2h99wu1jddrdr";
        form.appendChild(merchantKey);

        const amountF = document.createElement("input");
        amountF.type = "hidden";
        amountF.name = "amount";
        amountF.value = formatAmount(totalAmount);
        form.appendChild(amountF);

        const itemName = document.createElement("input");
        itemName.type = "hidden";
        itemName.name = "item_name";
        itemName.value = `${apartmentandbuilding.BuildingName} - ${apartment.ApartmentType}`;
        form.appendChild(itemName);

        const returnUrl = document.createElement("input");
        returnUrl.type = "hidden";
        returnUrl.name = "return_url";
        returnUrl.value = `https://jaesstudenthomes.co.za/Payment?status=success&user=${userId}`;
        form.appendChild(returnUrl);

        const cancelUrl = document.createElement("input");
        cancelUrl.type = "hidden";
        cancelUrl.name = "cancel_url";
        cancelUrl.value = "https://jaesstudenthomes.co.za/cancel?status=cancel";
        form.appendChild(cancelUrl);

        document.body.appendChild(form);
        form.submit();
    };

    const paymentRecord = async (paid) => {
        try {
            const paymentResponse = await fetch("https://backendjaes.passiontrials.co.za/payment_tb", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    UserId: userId,
                    ApartmentId: apartment.ApartmentId,
                    PayDate: date.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' }),
                    PayNextDate: nextPaymentDate.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' }),
                    PayAmount: totalAmount,
                    PayNote: "Monthly rent",
                    PaymentStatus: 'Pending',
                    PaymentType: "Rent",
                    PayAdvancePeriod: "15",
                    PayGracePeriod: "3",
                    PayMethod: "PayFast",
                    Paid: false,
                    PaidInAdvance: false,
                    PaidInAdvanceDate: "N/A",
                    IsGracePeriod: false,
                    ChosenPlan: chosenPlan,
                    ServiceFee: serviceFee,
                    RentCost: rentCost
                })
            });

            if (!paymentResponse.ok) {
                const errorText = await paymentResponse.text();
                throw new Error(`Failed to save payment record: ${errorText}`);
            }

            console.log('Payment recorded successfully!');
        } catch (error) {
            console.error("Error processing payment record:", error);
            alert(`Error during payment record: ${error.message}`);
        }
    };

    useEffect(() => {
        const handlePaymentStatus = async () => {
            const queryParams = new URLSearchParams(window.location.search);
            const status = queryParams.get('status');

            if (status === 'success') {
                console.log('user confirmed the payment');
                await paymentRecord(true);
            }
            else if (status === 'cancel') {
                console.log('user did not commit to pay');
                await paymentRecord(false);
            }

        };

        handlePaymentStatus();
        window.addEventListener('load', handlePaymentStatus);

        return () => {
            window.removeEventListener('load', handlePaymentStatus);
        };

    }, []);
    return (
        <div>
            <h1>Processing Payment...</h1>
            {!readyToBook && <div>Loading...</div>}
        </div>
    );
}

export default PaymentProcess;
