// import React from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { useAuth } from './AuthProvider';

// function Invoice() {
//     const location = useLocation();
//     const { date, apartment, apartmentandbuilding } = location.state;
//     const [loading, setLoading] = React.useState(false);
//     const navigate = useNavigate();
//     const pickedDate = date.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' });
//     const nextPaymentDate = new Date(date);
//     nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 1);
//     const { isLoggedIn } = useAuth();
//     const auth = useAuth();

//     const handlePayment = () => {

//         // if (!isLoggedIn) {
//         //     navigate('/login', { state: { from: location } });
//         //     return;

//         // }

//         setLoading(true);

//         setTimeout(() => {
//             setLoading(false);

//             navigate('/PaymentProcess', { state: { date, apartment, apartmentandbuilding } });
//         }, 5000);
//         // Simulate a delay of 5 seconds

//     };

//     if (!date || !apartment || !apartmentandbuilding) {
//         return <div>Loading...</div>;
//     }

//     return (
//         <div>
//             <h1>Invoice</h1>
//             <div>
//                 <h2>{apartmentandbuilding.BuildingName} - {apartment.ApartmentType}  </h2>
//                 <br />
//                 <p>Move in Date - {pickedDate}  / Checkout Date -{nextPaymentDate.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' })}</p>
//                 <p>Apartment number:{apartment.ApartmentNumber}</p>
//                 <p>{apartmentandbuilding.BuildingLocation}</p>
//                 <p>Guests allowed: {apartment.Guest}</p>
//                 <hr />
//                 <p>Apartment Price: R{apartment.ApartmentPrice}</p>
//                 <p>Utility Fees: R200 (example)</p>
//                 <p>Admin Fee: R300 (example)</p>
//                 <p>Total Due: R{apartment.ApartmentPrice + 20 + 30}</p>
//                 <p>Next Payment Date: {nextPaymentDate.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' })}</p>
//                 <button onClick={handlePayment} disabled={loading}>
//                     {loading ? 'Processing...' : 'Pay Now'}
//                 </button>
//             </div>


//         </div>

//     );
// }

// export default Invoice;
import React, { useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import Apartments from './Apartments';
import './invoice.css'
import './loaders.css'
import RegistrationModal from './components/RegistrationModal.js'; // Adjust the path based on your folder structure



function Invoice() {
    const location = useLocation();
    const { date, apartment, apartmentandbuilding } = location.state || {}; // Destructure with default empty object to prevent errors
    const [loading, setLoading] = React.useState(false);
    const [showRegistrationModal, setShowRegistrationModal] = useState(false); // State to show the registration modal
    const navigate = useNavigate();
    const { isLoggedIn, role } = useAuth();
    const [selectedPlan, setSelectedPayment] = React.useState('');


    let [totalAmount, setTotalAmount] = React.useState(null);
    let [serviceFee, setServiceFee] = React.useState(null);
    let [chosenPlan, setChosenPlan] = React.useState(null);
    let amount = null;
    const [thePrice, setThePrice] = React.useState(null);
    const [selectedFile, setSelectedFile] = React.useState(null);

    const [isEmergencyChecked, setIsEmergencyChecked] = useState(false);
    const [isHouseRulesChecked, setIsHouseRulesChecked] = useState(false);
    const [isIncidentFormChecked, setIsIncidentFormChecked] = useState(false);
    const [isLeaseClauseChecked, setIsLeaseClauseChecked] = useState(false);


    const allCheckboxesChecked = isEmergencyChecked && isHouseRulesChecked && isIncidentFormChecked && isLeaseClauseChecked;
    React.useEffect(() => {
        // Check if all required data is present before trying to access it
        if (!date || !apartment || !apartmentandbuilding) {
            console.error("Missing necessary data from location.state");
            setLoading(false); // Set loading false to show error or missing data message
            return; // Prevent further execution
        }
        setLoading(false);
        window.scrollTo(0, 0); // Data is present, so you can turn off loading
    }, [date, apartment, apartmentandbuilding]); // Ensure useEffect runs when any of these dependencies update



    const handlePayment2 = async () => {
        try {
            const amountInCents = Math.round(parseFloat(totalAmount) * 100);
            const response = await fetch('http://localhost:8900/api/pay', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    amount: amountInCents,
                    currency: 'ZAR',
                    successUrl: `https://jaesstudenthomes.co.za/Payment?status=success&user=50`,
                    cancelUrl: 'https://jaesstudenthomes.co.za/cancel?status=cancel'
                })
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Error: ${response.status} - ${errorText}`);
            }

            const data = await response.json();
            console.log('Payment response:', data);

            if (data.redirectUrl) {
                window.location.href = data.redirectUrl;
            }
        } catch (error) {
            console.error('Payment error:', error);
        }
    };


    // Function to handle payment
    const handlePayment = () => {

        if (role === 'Admin' || role === 'Super Admin') {
            alert('Admins are not permitted to book an apartment');
            navigate(-1);
            return;
        }

        if (selectedPlan && allCheckboxesChecked) {
            if (!isLoggedIn) {
                // Show the registration modal if user is not logged in
                setShowRegistrationModal(true);
                return;
            }

            setLoading(true);
            setTimeout(() => {
                setLoading(false);
                navigate('/YocoPayment', { state: { date, apartment, apartmentandbuilding: apartmentandbuilding.BuildingId, totalAmount, serviceFee, chosenPlan, nextPaymentDate } });
            }, 5000);
        } else if (!allCheckboxesChecked) {
            alert('Please agree to all the terms by checking the boxes.');
        } else {
            alert('Please select a payment plan');
        }
    };

    const handleGender = (binary) => {
        if (binary) {
            if (binary == 0) {
                return 'Female';
            }

            if (binary == 1) {

                return 'Male';
            }

            if (binary == 2) {
                return 'Mixed';
            }
        }
    }

    const handleSetup = (binary) => {

        if (binary) {
            if (binary == 0) {
                return "Single";
            }

            if (binary == 1) {
                return 'Single with Ensuite';
            }

            if (binary == 2) {
                return 'Sharing Double';
            }

            if (binary == 3) {
                return 'Sharing Double with Ensuite';
            }

            if (binary == 4) {
                return 'Sharing Triple';

            }

            if (binary == 5) {
                return 'Bachelor Pods';
            }
        }
    };

    const handleSelectedPlan = (e) => {
        const { name, value } = e.target;
        let plan = e?.target.options[e?.target.selectedIndex]?.text;
        setSelectedPayment(Number(value));
        console.log('this is the plan amount chosen', Number(value) + amount);
        // setTotalAmount(Number(value) + amount);
        setServiceFee(Number(value));

        setChosenPlan(plan);


        let splited = plan.split('-')[0].trim();

        console.log('its split :', splited);
        if (splited == 'Plan A') {
            amount = apartment?.ApartmentPrice;
            //represents plan a
            console.log("plan a:,", amount);
            setThePrice(amount);
            setTotalAmount(amount + Number(value));
        }

        if (splited == 'Plan B') {
            amount = apartment?.PriceB;
            console.log("plan b:,", amount);
            setThePrice(amount);
            setTotalAmount(amount + Number(value));

        }

    };

    // React.useEffect(() => {
    //     console.log('This is the chosen plan:', chosenPlan);
    //     setChosenPlan(chosenPlan);
    // }, [chosenPlan]);

    if (loading) {
        return <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh' // This ensures it takes the full viewport height
        }}>
            <div className="🤚">
                <div className="👉"></div>
                <div className="👉"></div>
                <div className="👉"></div>
                <div className="👉"></div>
                <div className="🌴"></div>
                <div className="👍"></div>
            </div>;     </div>// Show loading while checking for data
    }

    if (!date || !apartment || !apartmentandbuilding) {
        return <div>Error: Missing invoice data. Please ensure all data is provided.</div>; // Show error if data is missing
    }
    // Callback function after successful registration
    const handleRegistrationSuccess = () => {
        setShowRegistrationModal(false); // Close the modal
        // handlePayment();  Proceed with the payment after registration
    };
    // Format dates
    const pickedDate = new Date(date).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' }); //current date no picking
    const nextPaymentDate = new Date(date);
    nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 3);

    nextPaymentDate.setDate(new Date(nextPaymentDate.getFullYear(), nextPaymentDate.getMonth() + 1, 0).getDate());

    ///= apartment.ApartmentPrice;
    let currentDay = date.toLocaleDateString('en-US', { day: 'numeric' });
    let earlyDate = 0;
    let lateDate = 0;
    let AmountAfterDeduction = 0;
    let AmountAfterIncrease = 0;

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        const formData = new FormData();
        formData.append('image', selectedFile);

        try {
            const response = await fetch('http://localhost:8900/upload',
                {
                    headers: { 'Content-Type': 'multipart/form-data', },
                    method: "POST",
                    body: formData,
                });

            if (response.ok) {
                const data = await response.json();
                console.log('Image uploaded successfully: ', data);

            }
            else {
                console.error('Error uploading image:');
            }
        }
        catch (error) {
            console.error('error uploading image:', error);
        }

    };


    // if (currentDay > 1 && currentDay < 25) {

    //     currentDay = currentDay - 1;
    //     earlyDate = currentDay / 100 * amount;

    //     AmountAfterDeduction = amount - earlyDate;

    //     amount = AmountAfterDeduction;

    // }
    // else if (currentDay > 24 && currentDay <= 31) {
    //     currentDay = currentDay - 1;
    //     lateDate = currentDay / 100 * amount;

    //     AmountAfterIncrease = lateDate + amount;

    //     amount = AmountAfterIncrease;

    //     nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 1, 1);

    // }

    return (
        <>
            <div class="containerinvoice" style={{ paddingTop: '129px' }}>
                <div class="booking-form">
                    <div class="header">
                    <img src={`https://backendjaes.passiontrials.co.za/src${apartment.ApartmentRoomImage}`}
                        alt="Apartment Image" class="image" />
                        <div class="title">
                            {/* <span class="badge">Male</span> */}
                            <h2>{apartmentandbuilding.BuildingName} <br /> {apartment.ApartmentNumber}</h2>
                            <p> {handleSetup(apartment.LivingArrangement)}</p>
                        </div>
                    </div>
                    <>
                        <div class="step2">JAES STUDENT HOMES - GENERAL HOUSE RULES</div>
                        <p>The following is the general house rules for all properties. Each property has specific added terms that are to be adhered to in addition to the following:</p>


                        <ul class='bullet-list'>
                            <li>&#8226; Communal areas should be kept clean at all times.</li>
                            <li> &#8226; Gates and house entrances should be locked AT ALL TIMES for tenants’ safety (fine of the services deposit is applicable for constant offenders).</li>
                            <li>&#8226; Rooms should be kept locked when tenant is not on the premises. Tenant is not permitted to lend his/her keys to any individuals. No sharing of the rooms unless approved of by the lessor/owner.</li>
                            <li>&#8226; Every tenant’s privacy should be respected. Noise and music should be kept at a tolerable level for everyone.</li>
                            <li>&#8226; No smoking permitted inside the premises (only outside in allocated areas) and no DRUGS in the premises. No parties allowed.</li>
                            <li>&#8226; No washing is to be hanged in the bathroom.</li>
                            <li>&#8226; No more than 2 visitors per lessee allowed in the premises at a time. Visitors are the tenant’s full responsibility. No visitors are permitted to use additional services.</li>
                            <li>&#8226; No visitors allowed before 10am and after 21:00 pm. NO SLEEPOVERS.</li>
                            <li>&#8226; Down time after 21:30 pm.</li>
                        </ul>


                        <p>Fines (of up to 950) will be charged to tenant account for each rule break. In extreme cases termination of lease will take effect.</p>





                        <hr />

                        <div class="step">EMERGENCY PROTOCOLS</div>
                        <div className='emerg-row'>
                            <div>
                                <h4>FIRE</h4>
                                <p className='emergp'>
                                    &#8226; CALL FIRE STATION - 10111<br />
                                    &#8226;  CALL ADMIN - 081 500 6508
                                </p>
                            </div>
                            <div>
                                <h4>MEDICAL</h4>
                                <p className='emergp'>
                                    &#8226; CALL ADMIN - 081 500 6508<br />
                                    &#8226;  CALL NEXT OF KIN<br />
                                    &#8226;   CALL AMBULANCE - 10177 / 082 911
                                </p>
                            </div>
                        </div>

                        <div className='emerg-row'>
                            <div>
                                <h4>SECURITY</h4>
                                <p className='emergp'>
                                    &#8226; CALL ADMIN - 081 500 6508<br />
                                    &#8226;   CALL SECURITY COMPANY - 031 717 5000<br />
                                    &#8226;   CALL POLICE - 10111
                                </p>
                            </div>
                            <div>
                                <h4>NATURAL DISASTER</h4>
                                <p className='emergp'>
                                    &#8226;  CALL ADMIN - 081 500 6508<br />
                                    &#8226;  CALL LANDLORD<br />
                                    &#8226;   RUN !!!
                                </p>
                            </div>
                        </div>

                        <div className='emerg-row'>
                            <div>
                                <h4>OTHER</h4>
                                <p className='emergp'>&#8226; PRAY</p>
                            </div>
                            <div>
                            </div>
                        </div>

                    </>
                </div>
                <div class="booking-summary">
                    <div class="check-in-out">
                        <div class="check">
                            <p>Check In</p>
                            <strong>{pickedDate}</strong>
                        </div>
                        <div class="check-arrow">
                            <p>&gt;</p>
                        </div>
                        <div class="check">
                            <p>Check Out</p>
                            <strong> Nov 30, 2024</strong>
                        </div>
                    </div>

                    <div class="details">
                        <div class="detail-row">
                            <span class="label">Building:</span>
                            <span class="value">{apartmentandbuilding.BuildingName}</span>
                        </div>
                        <div class="detail-row">
                            <span class="label">Address:</span>
                            <span class="value">{apartmentandbuilding.BuildingLocation}</span>
                        </div>
                        <div class="detail-row">
                            <span class="label">Apartment Type:</span>
                            <span class="value">{apartment.LivingArrangement}</span>
                        </div>
                        <div class="detail-row">
                            <span class="label">Zone</span>
                            <span class="value">{apartmentandbuilding.Zone}</span>
                        </div>
                    </div>
                    <select
                        name='selectedPlan'
                        onChange={handleSelectedPlan}
                        value={selectedPlan}
                    >
                        <option value=''>Select Payment plan </option>
                        <option name='Plan A' value="3250">Plan A  - ( R3250 )</option>
                        <option name='Plan B' value='8500'>Plan B  - ( R8500)</option>
                    </select>
                    <div class="cost">
                        <p> </p>
                        <div class="cost-row">
                            <span class="label">Service Fee:</span>
                            <span class="value">R{selectedPlan}</span>
                        </div>
                        <p><span style={{ color: 'green' }}> </span></p>
                        <div class="cost-row">
                            <span class="label">Total Due:</span>
                            <span class="value">R {selectedPlan ? `${thePrice + selectedPlan}` : '0.00'}</span>
                        </div>
                        <div class="checkbox-section">
                            <div class="checkbox-item">
                                <input type="checkbox" id="emergency-protocol" name="emergency-protocol" checked={isEmergencyChecked} onChange={() => setIsEmergencyChecked(!isEmergencyChecked)} />
                                <label for="emergency-protocol">Emergency Protocol</label>
                            </div>
                            <div class="checkbox-item">
                                <input type="checkbox" id="house-rules" name="house-rules" checked={isHouseRulesChecked} onChange={() => setIsHouseRulesChecked(!isHouseRulesChecked)} />
                                <label for="house-rules">House Rules</label>
                            </div>
                            <div class="checkbox-item">
                                <input type="checkbox" id="incident-form" name="incident-form" checked={isIncidentFormChecked} onChange={() => setIsIncidentFormChecked(!isIncidentFormChecked)} />
                                <label for="incident-form">Incident Form</label>
                            </div>
                            <div class="checkbox-item">
                                <input type="checkbox" id="lease-clause" name="lease-clause" checked={isLeaseClauseChecked} onChange={() => setIsLeaseClauseChecked(!isLeaseClauseChecked)} />
                                <label for="lease-clause">Lease Clause</label>
                            </div>
                        </div>

                    </div>

                    <div class="due-today">
                        <button onClick={handlePayment} disabled={loading} style={{ backgroundColor: 'transparent', fontSize: '19px' }}>
                            {loading ? 'Processing...' : ` Pay R${thePrice + selectedPlan} `}
                        </button>
                    </div>
                </div>
                {/* Render the registration modal */}
                {showRegistrationModal && (
                    <RegistrationModal
                        date={date}
                        apartment={apartment}
                        apartmentandbuilding={apartmentandbuilding}
                        totalAmount={totalAmount}
                        serviceFee={serviceFee}
                        chosenPlan={chosenPlan}
                        nextPaymentDate={nextPaymentDate}
                        onSuccess={handleRegistrationSuccess}
                        onClose={() => setShowRegistrationModal(false)}
                    />

                )}
            </div>

            {/* <div style={{ paddingTop: '109px' }}>
          <h1>Invoice</h1>
          <div>
              <h2>{apartmentandbuilding.BuildingName} - {handleSetup(apartment.LivingArrangement)}</h2>
              <p>Move in Date - {pickedDate} / Checkout Date - {nextPaymentDate.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit', second: "2-digit", hour12: true })}</p>
              <p>Apartment number: {apartment.ApartmentNumber}</p>
              <p>{apartmentandbuilding.BuildingLocation}</p>
              <p>Guests allowed: </p>
              <hr />
              <p>Apartment Price: R{thePrice}</p>
             <p>Utility Fees: R200 (not counted)</p> 
              <select
  name="selectedPlan"
  onChange={handleSelectedPlan}
  value={selectedPlan}
>
  <option value="">Select Payment plan</option>
  <option name="Plan A" value="200">Plan A - (R200)</option>
  <option name="Plan B" value="300">Plan B - (R300)</option>
</select>


              <p>Service Fee: R{selectedPlan}</p>
              <br></br>
              <input type='file' onChange={handleFileChange} />
              <button onClick={handleUpload}>Upload your ID image</button>
           <p>


                  {`${totalAmount}` ? totalAmount : "no total"}


              </p> 
              <p>Total Due:<span style={{ color: 'green' }}> R{thePrice + selectedPlan}</span></p>
              <p>Next Payment Date: {nextPaymentDate.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' })}</p>
              <button onClick={handlePayment} disabled={loading}>
                  {loading ? 'Processing...' : ` Pay R${thePrice + selectedPlan}Now`}
              </button>
          </div>
      </div> */}
        </>
    );
}

export default Invoice;
