import React from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './apartment.css'

function ApartmentDetails() {
    const { apartmentId } = useParams();
    // Placeholder for apartment data
    const [apartment, setApartment] = React.useState(null);
    const [apartmentandbuilding, setApartmentAndBuilding] = React.useState(null);
    const [startDate, setStartDate] = React.useState(new Date());
    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        fetch(`https://backendjaes.passiontrials.co.za/apartment_tb/single/${apartmentId}`)
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    console.log(data);
                    setApartment(data.data[0]); // Assuming 'data.data' is an array
                }
            })
            .catch(error => console.error('Error:', error));

        fetch(`https://backendjaes.passiontrials.co.za/apartment_tb/singlewithjoint/${apartmentId}`)
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    console.log("this is the joint info", data);
                    setApartmentAndBuilding(data.data[0]);
                }
            }).catch(error => console.error('Error 2:', error))


    }, [apartmentId]);

    if (!apartment || !apartmentandbuilding) {
        return <div>Loading...</div>;
    }
    const handleSetup = (binary) => {

        if (binary) {
            if (binary == 0) {
                return "Single";
            }

            if (binary == 1) {
                return 'Single with Ensuite';
            }

            if (binary == 2) {
                return 'Sharing Double';
            }

            if (binary == 3) {
                return 'Sharing Double with Ensuite';
            }

            if (binary == 4) {
                return 'Sharing Triple';

            }

            if (binary == 5) {
                return 'Bachelor Pods';
            }
        }
    };

    // const today = new Date();
    // const nextMonthFirstDay = new Date(today.getFullYear(), today.getMonth() + 1, 1);

    //<input type="date" id="moveInDate" defaultValue={minDate} min={minDate} max={maxDate} style={{ width: '50%', padding: '10px' }} />
    // const formatDate = (date) => {
    //     let d = new Date(date),
    //         month = '' + (d.getMonth() + 1),
    //         day = '' + d.getDate(),
    //         year = d.getFullYear();

    //     if (month.length < 2)
    //         month = '0' + month;
    //     if (day.length < 2)
    //         day = '0' + day;

    //     return [year, month, day].join('-');
    // };

    // const formatDateDisplay = (date) => {
    //     const options = { day: "numeric", month: "long", year: 'numeric' };
    //     return date.toLocaleDateString('en-US', options);
    // };

    // const minDate = formatDate(today); // Today's date
    // const maxDate = formatDate(nextMonthFirstDay);
    // const displayDate = formatDateDisplay(today);
    const handleBooking = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            navigate('/invoice', { state: { date: startDate, apartment, apartmentandbuilding  } });
        }, 5000);
    };

    const today = new Date();
    const nextMonthFirstDay = new Date(today.getFullYear(), today.getMonth() + 1, 1);

    return (

        <div style={{ backgroundColor: '#fff' }}>
            <div className="breadcrumb-container" style={{ marginTop: '150px', backgroundColor: '#fff' }}>
                <div className="header-container">
                    <div className="header-text">{apartment.LivingArrangement} | {apartment.ApartmentNumber}</div>
                    <div className="subheader-text">{apartment[0]?.ApartmentNumber}</div>
                </div>
                <div className="breadcrumb">
                    <Link to="/"> Home </Link> / <Link to="/Properties"> Properties </Link> / Building Name /  {apartment.LivingArrangement}
                </div>
            </div>
            <div className="building-imagedETAIL" style={{ backgroundColor: '#fff' }}>
                <img src="https://homii.co.za/api/image?url=%2FRoomType%2Fdocument%3FRoomTypeId%3Dbc3ef84e-f852-4fb5-8056-01d0d2f1f9b9%26DocumentId%3Ddd30cd4d-ce9e-4986-83fa-5e5603a344e7" alt={apartment[0]?.BuildingName} />
            </div>

            <div className="apartment-details-grid">
                <div className="price-box">
                    <div style={{ flex: 1, padding: '20px' }}>
                        {/* <div style={{ marginBottom: '10px', fontSize: '14px', fontWeight: 'bold' }}>ONLY 1 LEFT</div> */}
                        <p>Choose move in Date </p>    <label htmlFor="moveInDate">
                            <DatePicker
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                dateFormat="d MMMM yyyy"
                                minDate={new Date()}
                                maxDate={nextMonthFirstDay}

                            />
                        </label>
                        <div style={{ fontSize: '18px', fontWeight: 'bold', margin: '20px 0' }}>PLAN A R{apartment.ApartmentPrice}pm / PLAN B R{apartment.PriceB}pm</div>
                        <button
                            onClick={handleBooking}
                            disabled={loading}
                            className="book-now-button"
                        >
                            {loading ? 'Processing...' : 'Continue '}
                        </button>

                    </div>
                </div>

                <div className="property-description">
                    <h3 class="h33">Property Description</h3>
                    <p>
                        {apartment.Description || "House"}
                    </p>
                </div>

                <div className="quick-summary">
                    <h3 class="h33">Quick Summary</h3>
                    <div><span className="label">Location:</span> <span className="value">{apartmentandbuilding.BuildingLocation || "Chicago, IL 606543"}</span></div>
                    <div><span className="label">Property :</span> <span className="value">{apartment.ApartmentType || "House"}</span></div>
                    <div><span className="label">Zone:</span> <span className="value">Sale</span></div>
                    <div><span className="label">Price:</span> <span className="value">    {apartment.ApartmentPrice || "House"}</span></div>
                    <div><span className="label">Beds:</span> <span className="value">{apartment.Beds || "4"}</span></div>
                    <div><span className="label">Baths:</span> <span className="value">{apartment.Baths || "2"}</span></div>
                    <div><span className="label">Garage:</span> <span className="value">{apartment.Garage || "1"}</span></div>
                </div>


                <div className="amenities">
                    <h3 class="h33">Amenities</h3>
                    <ul className="amenities-list">
                        <li>  <img src='https://www.svgrepo.com/show/520287/wifi.svg' /> Uncapped Wi-Fi</li>
                        <li> <img src='https://www.svgrepo.com/show/519848/transport.svg' />Transport</li>
                        <li><img src='https://www.svgrepo.com/show/533411/briefcase-alt-4.svg' /> JAES Alumni</li>
                        <li><img src='https://www.svgrepo.com/show/489310/kitchen.svg' /> Fully Kitted Houses</li>
                        <li><img src='https://www.svgrepo.com/show/346492/admin.svg' /> Full-Time Administration</li>
                        <li><img src='https://www.svgrepo.com/show/490453/tv-on.svg' /> Sky Glass TV</li>
                        <li><img src='https://www.svgrepo.com/show/521550/chart-pie.svg' /> Amicable Amenity Capacity Ratios</li>
                        <li><img src='https://www.svgrepo.com/show/154980/solar-panel-in-sunlight.svg' /> Loadshedding Solar System</li>
                        <li><img src='https://www.svgrepo.com/show/520756/graph-up.svg' /> Youth Development Programs</li>
                    </ul>
                </div>
            </div>

            {/* <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '109px', backgroundColor: '#fff', color: '#333' }}>
                {/* Apartment Details 
                <div style={{ flex: 3, marginRight: '20px' }}>
                    <h2>{apartment.ApartmentType} - {apartment.ApartmentNumber}</h2>
                    <div>{apartmentandbuilding.BuildingLocation} · Zone {apartmentandbuilding.Zone}</div>
                    <div>{apartment.LivingArrangement} apartment · Guest allowed:{apartment.Guest}</div>
                    <p>Additional details about the apartment...</p>
                </div>
    
                {/* Payment and Booking Section 
                <div style={{ flex: 1, backgroundColor: '#444', padding: '20px' }}>
                    <div style={{ marginBottom: '10px', fontSize: '14px', fontWeight: 'bold' }}>ONLY 1 LEFT</div>
                    <label htmlFor="moveInDate">Move in Date    <DatePicker
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        dateFormat="d MMMM yyyy"
                        minDate={new Date()}
                        maxDate={nextMonthFirstDay}
    
                    />
                    </label>
                    <div style={{ fontSize: '18px', fontWeight: 'bold', margin: '20px 0' }}>PLAN A R{apartment.ApartmentPrice}/ PLAN B R{apartment.PriceB} per month</div>
                    <button onClick={handleBooking} diabled={loading} style={{ backgroundColor: '#ff5a5f', color: 'white', border: 'none', padding: '10px 20px', width: '100%', cursor: 'pointer' }}>
                        {loading ? 'Processing...' : 'Book Now'}
                    </button>
                </div>
            </div> */}
        </div>

    );
}

export default ApartmentDetails;
