import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './transport.css'

const Transport = () => {
    const [selectedBuilding, setSelectedBuilding] = useState("");
    const [institutions, setInstitutions] = useState([
        "Ukzn Howard", "UKZN Medical", "Richfield Campus", "DUT Steve Biko", "Umbilo College", "Coastal College"
    ]);
    const [selectedInstitution, setSelectedInstitution] = useState("");
    const [selectedDirection, setSelectedDirection] = useState("");
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [availableTimes, setAvailableTimes] = useState([]);
    const [selectedTime, setSelectedTime] = useState(""); // Track selected time
    const [bookedTransports, setBookedTransports] = useState([]); // To store the user's booked transports
    const [reload, setReload] = useState(false);

    const location = useLocation();
    const { buildingName, userId } = location.state || {}; // Assuming userId is passed through location.state

    useEffect(() => {
        if (buildingName) {
            setSelectedBuilding(buildingName);
        }
    }, [buildingName]);

    // Fetch the user's booked transport data
    useEffect(() => {
        if (userId) {
            fetch(`http://localhost:8901/api/transport/${userId}`)
                .then(response => response.json())
                .then(data => setBookedTransports(data))
                .catch(error => console.error('Error fetching transport data:', error));
        }
    }, [userId, reload]); // Fetch data when either userId or reload changes



    // Function to update available times based on institution and direction
    useEffect(() => {
        let times = [];
        if (selectedInstitution === "Ukzn Howard" && selectedDirection === "To Home") {
            times = ["12:00", "17:30", "20:00"];
        } else if (selectedInstitution === "Ukzn Howard" && selectedDirection === "To Institution") {
            times = ["07:45", "10:00", "12:00"];
        } else if (selectedInstitution === "UKZN Medical" && selectedDirection === "To Home") {
            times = ["12:00", "17:30", "20:00"];
        } else if (selectedInstitution === "UKZN Medical" && selectedDirection === "To Institution") {
            times = ["07:45", "10:00", "12:00"];
        } else if (selectedInstitution === "Coastal College" && selectedDirection === "To Home") {
            times = ["14:00"];
        } else if (selectedInstitution === "Coastal College" && selectedDirection === "To Institution") {
            times = ["08:00"];
        } else if (selectedInstitution === "Umbilo College" && selectedDirection === "To Home") {
            times = ["14:00"];
        } else if (selectedInstitution === "Umbilo College" && selectedDirection === "To Institution") {
            times = ["08:00"];
        } else if (selectedInstitution === "DUT Steve Biko" && selectedDirection === "To Home") {
            times = ["17:00"];
        } else if (selectedInstitution === "DUT Steve Biko" && selectedDirection === "To Institution") {
            times = ["08:00"];
        } else if (selectedInstitution === "Richfield Campus" && selectedDirection === "To Home") {
            times = ["17:00"];
        } else if (selectedInstitution === "Richfield Campus" && selectedDirection === "To Institution") {
            times = ["08:00"];
        }
        setAvailableTimes(times);
        setSelectedTime(""); // Reset selected time whenever options change
    }, [selectedInstitution, selectedDirection]);

    const handleBuildingChange = (e) => {
        setSelectedBuilding(e.target.value);
    };

    const handleInstitutionChange = (e) => {
        setSelectedInstitution(e.target.value);
    };

    const handleDirectionChange = (e) => {
        setSelectedDirection(e.target.value);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleTimeSelect = (time) => {
        setSelectedTime(time); // Set the selected time
    };

    const handleSaveTransport = () => {
        const transportData = {
            userId,
            buildingName: selectedBuilding,
            institution: selectedInstitution,
            direction: selectedDirection,
            transportDate: selectedDate.toISOString().split('T')[0],
            transportTime: selectedTime,
        };

        fetch('http://localhost:8901/api/transport', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(transportData),
        })
            .then(response => response.json())
            .then(data => {
                alert('Transport data saved successfully!');
                setReload(prev => !prev); // Toggle reload state to force a rerender
            })
            .catch(error => console.error('There was an error saving the transport data!', error));
    };



    // Function to filter out dates that are not in the current week and exclude weekends
    const isWeekdayInCurrentWeek = (date) => {
        const today = new Date();
        const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 1)); // Start from Monday
        const endOfWeek = new Date(today.setDate(startOfWeek.getDate() + 4)); // End on Friday (Monday + 4)

        const day = date.getDay();
        const isWeekday = day !== 0 && day !== 6; // Exclude Sunday (0) and Saturday (6)

        return isWeekday && date >= startOfWeek && date <= endOfWeek;
    };

    // Format date in display as "Tues, 2 July"
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date)) return "Invalid Date"; // Handle invalid dates
        const options = { weekday: 'short', day: 'numeric', month: 'short' };
        return date.toLocaleDateString('en-US', options);
    };


    // Compare transport date with today's date + 2 days to determine status
    const getStatus = (dateString) => {
        const today = new Date();
        const datePlusTwo = new Date();
        datePlusTwo.setDate(today.getDate() + 2);

        const transportDate = new Date(dateString);

        // Mark as "Inactive" if the transport date is equal to today's date + 2 days
        if (transportDate.getDate() === datePlusTwo.getDate() &&
            transportDate.getMonth() === datePlusTwo.getMonth() &&
            transportDate.getFullYear() === datePlusTwo.getFullYear()) {
            return "Inactive";
        }

        // Otherwise, it is "Active"
        return "Active";
    };

    return (
        <div className="transport-container" style={{ display: 'flex', justifyContent: 'space-between', padding: '0px', marginTop: '109px', width:'100%' }}>
            {/* Transport Booking Form */}
            <div style={{ width: '60%' }}>
                <h2>Transport Booking</h2>

                {/* Building Dropdown */}
                <label htmlFor="building">Building: </label>
                <select id="building" value={selectedBuilding} onChange={handleBuildingChange}>
                    <option value="">Select Building</option>
                    {selectedBuilding && <option value={selectedBuilding}>{selectedBuilding}</option>}
                </select>

                {/* Institution Dropdown */}
                <label htmlFor="institution" style={{ marginLeft: '20px' }}>Institution: </label>
                <select id="institution" value={selectedInstitution} onChange={handleInstitutionChange}>
                    <option value="">Select Institution</option>
                    {institutions.map((institution, index) => (
                        <option key={index} value={institution}>{institution}</option>
                    ))}
                </select>

                {/* Direction Dropdown */}
                <label htmlFor="direction" style={{ marginLeft: '20px' }}>Direction: </label>
                <select id="direction" value={selectedDirection} onChange={handleDirectionChange}>
                    <option value="">Select Direction</option>
                    <option value="To Home">To Home</option>
                    <option value="To Institution">To Institution</option>
                </select>

                {/* Date Picker with current week (Monday-Friday) filter */}
                <div style={{ marginTop: '20px' }}>
                    <label htmlFor="date">Select Date: </label>
                    <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        dateFormat="yyyy-MM-dd"
                        filterDate={isWeekdayInCurrentWeek} // Apply current week and weekday filter
                    />
                </div>

                {/* Time Buttons */}
                {availableTimes.length > 0 && (
                    <div style={{ marginTop: '20px' }}>
                        <label>Select Time:</label>
                        <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                            {availableTimes.map((time, index) => (
                                <button
                                    key={index}
                                    onClick={() => handleTimeSelect(time)}
                                    style={{
                                        padding: '10px 20px',
                                        backgroundColor: selectedTime === time ? '#ff5a5f' : '#f0f0f0', // Highlight selected time
                                        color: selectedTime === time ? '#fff' : '#000',
                                        border: '1px solid #ccc',
                                        borderRadius: '5px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    {time}
                                </button>
                            ))}
                        </div>
                    </div>
                )}

                {/* Save Button */}
                {selectedTime && (
                    <div style={{ marginTop: '20px' }}>
                        <button onClick={handleSaveTransport} style={{ padding: '10px 20px', backgroundColor: '#ff5a5f', color: '#fff' }}>
                            Save Transport
                        </button>
                    </div>
                )}

                {/* Display selected time */}
                {selectedTime && (
                    <div style={{ marginTop: '20px' }}>
                        <p>Selected Time: <strong>{selectedTime}</strong></p>
                    </div>
                )}

                {/* Display booked transports */}
                <div style={{ padding: '20px', marginTop: '109px' }}>
                    <h2>Transport Booking</h2>

                    {/* Display booked transports */}
                    {Array.isArray(bookedTransports) && bookedTransports.length > 0 ? (
                        <div style={{ marginTop: '20px' }}>
                            <table style={{ width: '100%', borderCollapse: 'collapse', borderRadius: '5px', overflow: 'hidden' }}>
                                <thead>
                                    <tr style={{ backgroundColor: '#f8f8f8', textAlign: 'left' }}>
                                        <th style={{ padding: '10px' }}>Date</th>
                                        <th style={{ padding: '10px' }}>Time</th>
                                        <th style={{ padding: '10px' }}>Direction</th>
                                        <th style={{ padding: '10px' }}>Institution</th>
                                        <th style={{ padding: '10px' }}>Building</th>
                                        <th style={{ padding: '10px' }}>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bookedTransports.map((booking, index) => (
                                        <tr key={index} style={{ borderBottom: '1px solid #ccc' }}>
                                            <td style={{ padding: '10px' }}>{formatDate(booking.transport_date)}</td>
                                            <td style={{ padding: '10px' }}>{booking.transport_time}</td>
                                            <td style={{ padding: '10px' }}>{booking.direction}</td>
                                            <td style={{ padding: '10px' }}>{booking.institution}</td>
                                            <td style={{ padding: '10px' }}>{booking.building_name}</td>
                                            <td style={{ padding: '10px' }}>{getStatus(booking.transport_date)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <p>No transports booked yet.</p>
                    )}
                </div>
            </div>

            {/* Transport T&Cs */}
            <div className="transport-tc" style={{ width: '35%', paddingLeft: '20px', borderLeft: '1px solid #ccc' }}>
                <h3>Transport T&Cs</h3>
                <ul>
                    <li>Please arrive at the pick-up location on campus before the time.</li>
                    <li>Transport will wait at the gate on campus for 10 minutes. If you fail to arrive before the transport leaves, you’ll have to wait for the next pick-up time or find your own way home.</li>
                    <li>No eating in the car.</li>
                    <li>Radio will stay switched off. No music requests are accepted.</li>
                    <li>No speakers are to be played in the transport.</li>
                    <li>Please limit your interaction with the drivers.</li>
                    <li>If you have any issues/complaints, please log them on the service box on the website.</li>
                </ul>
            </div>
        </div>
    );
}

export default Transport;
