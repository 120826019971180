import { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';
import './conactForm.css'; // Importing the CSS file for styling

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [disabled, setDisabled] = useState(false);
  const [alertInfo, setAlertInfo] = useState({
    display: false,
    message: '',
    type: '',
  });

  // Use useRef to reference the form
  const form = useRef();

  // Shows alert message for form submission feedback
  const toggleAlert = (message, type) => {
    setAlertInfo({ display: true, message, type });

    setTimeout(() => {
      setAlertInfo({ display: false, message: '', type: '' });
    }, 5000);
  };

  // Function called on submit that uses emailjs to send email of valid contact form
  const onSubmit = async (data) => {
    // Destructure data object
    const { name, email, subject, message } = data;
    try {
      // Disable form while processing submission
      setDisabled(true);

      // Define template params
      const templateParams = {
        name,
        email,
        subject,
        message,
      };

      // Send the email using emailjs
      await emailjs.sendForm(
        'service_f7lohhb',
        'template_uyfgtt7',
        form.current,
        'sp9R93pUz8ZbOG-HN'
      );

      // Display success alert
      toggleAlert('Form submission was successful!', 'success');
    } catch (e) {
      console.error(e);
      // Display error alert
      toggleAlert('Uh oh. Something went wrong.', 'danger');
    } finally {
      // Re-enable form submission
      setDisabled(false);
      // Reset contact form fields after submission
      reset();
    }
  };

  return (
    <div className="cosmicFormWrapper">
      <form
        ref={form}
        id="cosmic-contact-form"
        className="nebulaForm"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="galaxy-group">
          <input
            type="text"
            name="name"
            className={`starlightInput ${errors.name ? 'meteorErrorInput' : ''}`}
            placeholder="Your Name"
            {...register('name', {
              required: 'Please enter your name',
              maxLength: {
                value: 30,
                message: 'Name cannot exceed 30 characters',
              },
            })}
          />
          {errors.name && <p className="cometErrorMessage">{errors.name.message}</p>}
        </div>

        <div className="galaxy-group">
          <input
            type="email"
            name="email"
            className={`starlightInput ${errors.email ? 'meteorErrorInput' : ''}`}
            placeholder="Your Email"
            {...register('email', {
              required: 'Please enter a valid email address',
              pattern: {
                value:
                  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: 'Please enter a valid email address',
              },
            })}
          />
          {errors.email && <p className="cometErrorMessage">{errors.email.message}</p>}
        </div>

        <div className="galaxy-group">
          <input
            type="text"
            name="subject"
            className={`starlightInput ${errors.subject ? 'meteorErrorInput' : ''}`}
            placeholder="Subject"
            {...register('subject', {
              required: 'Please enter a subject',
              maxLength: {
                value: 75,
                message: 'Subject cannot exceed 75 characters',
              },
            })}
          />
          {errors.subject && (
            <p className="cometErrorMessage">{errors.subject.message}</p>
          )}
        </div>

        <div className="galaxy-group">
          <textarea
            name="message"
            className={`starlightInput ${errors.message ? 'meteorErrorInput' : ''}`}
            rows="4"
            placeholder="Your Message"
            {...register('message', {
              required: 'Please enter a message',
            })}
          ></textarea>
          {errors.message && (
            <p className="cometErrorMessage">{errors.message.message}</p>
          )}
        </div>

        <button
          type="submit"
          className="galaxySubmitButton"
          disabled={disabled}
        >
          Send Message
        </button>
      </form>

      {alertInfo.display && (
        <div className={`nebulaAlert nebula-${alertInfo.type}`}>
          {alertInfo.message}
        </div>
      )}
    </div>
  );
};

export default ContactForm;
