import React, { useRef, useState, useEffect } from 'react';
import { Route, Routes, Link, Outlet, useNavigate } from 'react-router-dom';
import MyApartment from './myApartment';
import { useAuth } from './AuthProvider';
import Countdown from "react-countdown";
//import { set } from 'react-datepicker/dist/date_utils';
import './modal.css';
import './userdashboard.css';
//import Loader2 from './refresh';




// Dummy data for demonstration
const userData = {
    bookings: [
        { id: 1, location: "Beach House", date: "2024-07-20" },
        { id: 2, location: "Mountain Cabin", date: "2024-08-15" }
    ],
    payments: [
        { id: 1, amount: "1500", status: "Completed", date: "2024-07-01" },
        { id: 2, amount: "1200", status: "Pending", date: "2024-08-01" }
    ],
    profile: {
        name: "Mark Savage",
        imageUrl: "https://via.placeholder.com/150"
    }
};

// Placeholder components for the new routes
function Favorites() {
    return <div><h2>My Favorites</h2><p>Favorite listings and saved properties will be shown here.</p></div>;
}

function Profile() {
    return <div><h2>My Profile</h2><p>Profile settings and personal information can be updated here.</p></div>;
}

function CurrentApartment() {
    return <div><h2>Current Apartment</h2><p>Details about your current apartment will be displayed here.</p></div>;
}

function useOutsideAlerter(ref, onOutsideClick) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onOutsideClick();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, onOutsideClick]);
}
const PaymentModal = ({ isVisible, onClose, onSubmit }) => {
    const [customAmount, setCustomAmount] = useState('');
    const [selectedAmount, setSelectedAmount] = useState(null);
    const [isCustom, setIsCustom] = useState(false);

    const handleAmountChange = (e) => {
        setCustomAmount(e.target.value);
        // setSelectedAmount('');  // Clear predefined amounts if custom is entered
    };



    const handleSubmit = () => {
        const amount = isCustom ? customAmount : selectedAmount;
        if (!amount) {
            alert('Please select or enter an amount.');
            return;
        }
        onSubmit(amount); // Pass the amount to the parent component
    };

    const handleAmountSelect = (amount) => {
        setSelectedAmount(amount);
        setIsCustom(false); // Deselect custom option if a preset amount is chosen
    };

    const handleCustomSelect = () => {
        setIsCustom(true); // Show custom input when "Custom" is selected
        setSelectedAmount(null); // Clear preset selection
    };

    if (!isVisible) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <h2>Select or Enter Payment Amount</h2>
                <div className="amount-options">
                    <button onClick={() => handleAmountSelect(500)}>R500</button>
                    <button onClick={() => handleAmountSelect(1000)}>R1000</button>
                    <button onClick={() => handleAmountSelect(1500)}>R1500</button>
                    <button onClick={() => handleAmountSelect(2000)}>R2000</button>
                    <button onClick={handleCustomSelect}>Custom</button>
                </div>
                {isCustom && (
                    <div className="custom-amount">
                        <input
                            type="number"
                            placeholder="Enter amount"
                            value={customAmount}
                            onChange={handleAmountChange}
                        />
                    </div>
                )}
                <button onClick={handleSubmit} className="submit-btn">Submit</button>
            </div>
        </div>
    );
};

const Loader = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh' // This ensures it takes the full viewport height
        }}>
            <div className="🤚">
                <div className="👉"></div>
                <div className="👉"></div>
                <div className="👉"></div>
                <div className="👉"></div>
                <div className="🌴"></div>
                <div className="👍"></div>
            </div>
        </div>
    );
};

const CheckoutModal = ({ isVisible, onClose, onConfirm }) => {
    if (!isVisible) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Confirm Checkout</h2>
                <p>Are you sure you want to checkout?</p>
                <div className="modal-buttons">
                    <button onClick={onConfirm} className="confirm-btn">Confirm</button>
                    <button onClick={onClose} className="cancel-btn">Cancel</button>
                </div>
            </div>
        </div>
    );
};

function UserDashboard() {
    const [bookings, setBookings] = useState([]);
    const [payments, setPayments] = useState([]);
    const [profile, setProfile] = useState({});
    const [showDropdown, setShowDropdown] = useState(false);
    const [fetchid, setFetchId] = useState(null);
    const { userId } = useAuth();
    const [error, setError] = useState('');
    const [apartment, setApartment] = useState(null);
    const [creditAmount, setCreditAmount] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [enteredAmount, setEnteredAmount] = useState(null);
    const navigate = useNavigate();
    const [latestInfo, setLatestInfo] = useState(null);
    const [latestPaymentTrack, setLatestPaymentTrack] = useState(null);
    const [showCheckoutModal, setShowCheckoutModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const [walletData, setWalletData] = useState(null); // Added state for wallet data

    let theFirst = null;
    let showButtons = null;
    let testCount = null;
    let informedMessage = null;
    let tc = 0;
    let systemInitiated = null;
    const systemProcessCallRef = useRef(false);
    let stopper = 0;

    const wrapperRef = useRef(null);

    ///// this controls the buttons that are shown
    theFirst = latestInfo?.PayDate;

    // theFirst = new Date(theFirst).toLocaleDateString('en-US', { day: 'numeric' });
    //  showButtons = theFirst >= 15 && theFirst <= 31;
    /////

    useOutsideAlerter(wrapperRef, () => setShowDropdown(false));

    useEffect(() => {
        setBookings(userData.bookings);
        setPayments(userData.payments);
        setProfile(userData.profile);



        
        fetch(`https://backendjaes.passiontrials.co.za/apartment-check/${userId}`, {
            headers: { "Content-Type": "application/json" }
        })
            .then(response => {
                if (!response.ok) { throw new Error("Failed to get a match with user"); }
                return response.json();
            }).
            then(data => {
                if (data) {
                    setFetchId(data);
                    console.log('this is the id for the room: ', data);
                }
                else {
                    console.log('No apartment associated with this user');
                }

            }).
            catch(err => {
                console.error('Error:', err);
                setError(err.message);
            });
    }, []);

    // const nextPaymentDate = new Date(new Date().setDate(new Date().getDate() + 30)); // Assuming 30 days till next payment
    // const currentDate = new Date();
    // const daysLeft = Math.ceil((nextPaymentDate - currentDate) / (1000 * 60 * 60 * 24));
    //eeei
    useEffect(() => {

        //  fetchApartmentDetails();
        if (!fetchid) return;
        fetch(`https://backendjaes.passiontrials.co.za/user_full_details/${userId}`, {
            headers: { "Content-Type": 'application/json' }
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    console.log(data[0]);
                    setApartment(data[0]);
                }
                else {
                    console.log('No apartement data found here');

                }
            })
            .catch(error => {
                console.error('Error', error);
                setError('Failed to load user data');
            });

        fetch(`https://backendjaes.passiontrials.co.za/latest_record/${userId}/${fetchid}`, {
            headers: { 'Content-Type': 'application/json' }
        }).then(response => response.json())
            .then(data => {
                if (data) {
                    console.log("latest record", data);
                    setLatestInfo(data);
                }
                else {
                    console.log('No valid payment record found');
                }
            }).catch(error => {
                console.error('Error', error);
                setError('Failed to load latest record');
            });

        fetch(`https://backendjaes.passiontrials.co.za/latest_payment_tracking/${userId}`, {
            headers: { 'Content-Type': 'application/json' }
        }).then(response => response.json())
            .then(data => {
                if (data) {
                    console.log("latest payment track record", data);
                    setLatestPaymentTrack(data);
                }
                else {
                    console.log('No valid payment tracking record found');
                }
            }).catch(error => {
                console.error('Error with tracking', error);
                setError('Failed to load latest pay tracking record');
            });

            fetch(`https://backendjaes.passiontrials.co.za/wallet/${userId}`, {
                headers: { 'Content-Type': 'application/json' }
            })
                .then(response => response.json())
                .then(data => {
                    if (data && data.length > 0) {
                        console.log('Wallet data:', data);
                        setWalletData(data[0]); // Assuming we want the latest wallet record
                    } else {
                        console.log('No wallet data found');
                    }
                })
                .catch(error => {
                    console.error('Error fetching wallet data:', error);
                    setError('Failed to load wallet data');
                });


    }, [userId, fetchid]);

    // function fetchApartmentDetails() {
    //     const apartmentDetails = {
    //         name: "Luxury Beach House",
    //         imageUrl: "https://picsum.photos/200/300?random=30"
    //     };

    //     setApartment(apartmentDetails);
    // }
    const nextPayDate = latestInfo?.PayNextDate;
    console.log(`non converted :${nextPayDate}`);
    const nextPayDay = new Date(nextPayDate).toLocaleDateString('en-US', { day: "numeric" });
    const converted = nextPayDate ? new Date(nextPayDate) : null;
    console.log(`converted :${converted}`);

    //    nextPaymentDate.setDate(new Date(nextPaymentDate.getFullYear(), nextPaymentDate.getMonth() + 1, 0).getDate());

    //converted.setDate(new Date(converted.getFullYear(), converted.getMonth() + 1, 0).getDate());
    const dateNow = new Date();
    const daysOfStay = converted ? Math.ceil((converted - dateNow) / (1000 * 60 * 60 * 24)) : null;
    console.log('this is the converted date', converted, "this is the current date", dateNow);


    // if (!apartment) {
    //     return <div>Loading</div>
    // }
    const systemProcess = async () => {   //intricate function i will come back to ,

        // if (systemInitiated) {

        let newDate = latestInfo.PayNextDate;

        let converted = new Date(newDate);

        converted.setMonth(converted.getMonth() + 1, 1);
        let formatedDate = converted.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' });


        const systemRecord = await fetch('https://backendjaes.passiontrials.co.za/payment_tb', {
            method: 'POST',
            headers: { 'Content-Type': "application/json" },
            body: JSON.stringify({
                UserId: latestInfo.UserId,
                ApartmentId: latestInfo.ApartmentId,
                PayDate: latestInfo.PayNextDate,
                PayNextDate: formatedDate,
                PayAmount: latestInfo.PayableRent,
                PayNote: 'System Initiated',
                PaymentStatus: 'OVERDUE',
                PaymentType: "Rent",
                PayAdvancePeriod: '15',
                PayGracePeriod: '3',
                PayMethod: "No Payment Attempt",
                Paid: true,
                PaidInAdvance: false,
                PaidInAdvanceDate: "N/A",
                IsGracePeriod: true,
                ChosenPlan: latestInfo.ChosenPlan,
                ServiceFee: latestInfo.ServiceFee,
                PayableRent: latestInfo.PayableRent,
                RentCost: latestInfo.RentCost

            })
        });

        // }

    };


    if (latestInfo) {
        theFirst = new Date(latestInfo.PayDate).toLocaleDateString('en-US', { day: 'numeric' });
        const currentD = new Date(dateNow).toLocaleDateString('en-US', { day: 'numeric' });
        showButtons = theFirst >= 1 && theFirst <= 31 || currentD >= 1 && currentD <= 31;
        //showButtons = true; for testing
        testCount = latestInfo?.PayNote == 'Checkout Month';
        tc = latestInfo?.PayNote == 'Monthly rent'; //has no html code to go with yet

        let convertedDate = new Date(latestInfo.PayNextDate);
        let currentDate = new Date();

        systemInitiated = convertedDate < currentDate;
        // if (daysOfStay < 0) {


        //     console.log('the condition is true');
        //     if (latestInfo?.PayNote == 'System Initiated') {
        //         systemProcess();
        //         console.log('the record of the system is recorded');
        //     }
        //     // 
        // }

    }

    useEffect(() => {
        if (latestInfo) {
            let convertedDate = new Date(latestInfo.PayNextDate);
            let currentDate = new Date();

            systemInitiated = convertedDate < currentDate;

            if (systemInitiated && daysOfStay < 0) {
                console.log('outside the function');
                if (latestInfo.PayNote == 'Monthly rent') {
                    systemProcess();
                    console.log('the function is called');
                }
            }

        }
    }, [latestInfo]);

    // if (daysOfStay ==)
    informedMessage = latestInfo?.PaymentStatus == 'OVERDUE';



    const toggleDropdown = (e) => {
        e.stopPropagation();
        setShowDropdown(!showDropdown);
    };
    const backToDash = () => {
        navigate('/UserDashboard');
    }

    const checkOut = () => {

      
            // Check if DaysPaidFor is less than 0
            
        
            // Proceed with the remaining balance check
            if (walletData?.DaysPaidFor > 0) {
                // Show the checkout modal if the remaining balance is 0 or less
                setShowCheckoutModal(true);
            } else {
                alert(`You cannot checkout without settling outstanding payment for the month of ${latestPaymentTrack?.MonthAndYearPaidFor}`);
                return;
            }
       
        


    }

    const confirmCheckout = () => {
        setShowCheckoutModal(false);
        setLoading(true); // Start the loader

        // Simulate delay before proceeding with checkout
        setTimeout(() => {
            setLoading(false); // Stop the loader
            navigate(`/checkOut?payid=${userId}`);
        }, 7000);
    };

    const handleModalClose = () => {
        setShowCheckoutModal(false);
    };
 // Calculate number of days in the current month
 const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
};

// Get the current date and determine the number of days in the current month
const currentDate = new Date();
const currentMonth = currentDate.getMonth(); // 0 = January, 11 = December
const currentYear = currentDate.getFullYear();
const daysInMonth = getDaysInMonth(currentYear, currentMonth); // Get the number of days in the current month

// Calculate daily pay based on apartment rent
const rentAmount = apartment?.ApartmentPrice || 0;
const dailyPay = rentAmount / daysInMonth; // Calculate daily pay
const handleModalSubmit = (amount) => {

    // Set the entered amount and close the modal
    setEnteredAmount(amount);
    setShowModal(false);  // Close the modal

    // Navigate to the recurringPayment page with the given state
    navigate('/recurringPayment', { 
        state: { amount, apartment, nextPayDate, latestInfo, latestPaymentTrack, dailyPay } 
    });
};




    const goToPayment = () => {

        let count = 0;
        const todaysDate = new Date();
        const futurePayDate = new Date(latestInfo?.PayNextDate);
        const theDays = todaysDate.toLocaleDateString('en-US', { day: 'numeric' });
        if (showButtons) {

            //  if (!latestInfo?.PaymentStatus.trim() == 'OVERDUE') {

            //daysOfStay < 30  daysOfStay > 0

              console.log('This should have something', latestInfo);
                setShowModal(true);


          
        }
    }

    if (!apartment) {
        return (
            <div style={{ marginTop: '109px' }}>
                <h2>You have not booked an apartment yet.</h2>
                <Link to="/">Click here to book an apartment</Link>
            </div>


        );
    }

    if (loading) {
        return <Loader />; // Show only the loader while loading is true
    }
    const goToTransport = () => {
        if (apartment && apartment.BuildingName) {
            navigate(`/transport`, { state: { buildingName: apartment.BuildingName, userId: userId } });
        }
    };

    return (
        <div >

            <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', padding: '20px', }}>

                {/* <div ref={wrapperRef} onClick={toggleDropdown} style={{ display: 'flex', alignItems: "center", cursor: "pointer" }}>
                    <img style={{ width: "50px", height: "50px", borderRadius: '50%', marginRight: "10px", objectFit: 'cover' }} src={`http://http://backendjaes.passiontrials.co.za/${apartment.FaceImage}` || "https://picsum.photos/200/300?random=30"} alt='User' className="profile-image" />
                    <span>{apartment.Name} {apartment.Surname}</span>


                    {showDropdown && <DropdownMenu />}
                </div> */}
                <PaymentModal
                    isVisible={showModal}
                    onClose={() => setShowModal(false)}
                    onSubmit={handleModalSubmit}
                />
                <CheckoutModal
                    isVisible={showCheckoutModal}
                    onClose={handleModalClose}
                    onConfirm={confirmCheckout}
                />

            </div>
            <nav>
                {/* <Link to={`MyApartment/${userId}/${fetchid}`}>My Apartment</Link> */}
                {/* <Link to="/UserPayment">Payments</Link> |
                <Link to="/favorites">Favorites</Link> |
                <Link to="/profile">Profile</Link> */}
                {/* <Link to="/current-apartment">Current Apartment</Link> */}
                {/* <button onClick={goToTransport}>Go to Transport</button> */}
            </nav>
            <Routes>
                <Route path="/bookings" element={<BookingList bookings={bookings} />} />
                <Route path="/payments" element={<PaymentList payments={payments} />} />
                <Route path="/favorites" element={<Favorites />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/current-apartment" element={<CurrentApartment />} />
            </Routes>
            <Outlet />
            <body class="dashboard-page">  
            <div class="dashboard-header">
    <h1 class="welcome-message">Welcome <br/> {apartment.Name}</h1>
  </div>
                <div class="dashboard">
                    {/* Inside the card */}
                  
                    <div class="card first-card">
                        <img style={{ zIndex: '0' }} src="https://backendjaes.passiontrials.co.za/src/Building_images/IMG_20200725_132724.jpg" alt="Account Image" class="card-bg-img" />
                        {/* <h2 style={{ color: 'white' }}> {testCount ? `Next payment is in: ${daysOfStay} days` : `Next payment is in: ${daysOfStay} days`}
                        </h2> */}
                        {walletData && (
    <h2 style={{ color: 'white' }}>
        Days Paid For: {walletData.DaysPaidFor}
    </h2>
)}
                        <h2 style={{ color: 'white' }}>  {systemInitiated ?
                            "its overdue" : ""
                        }</h2>
                        <small style={{ color: 'white' }}>{apartment.BuildingName} {apartment.ApartmentType} </small>
                        <div class="card-buttons" style={{display:'none'}}>
                            <button class="button-primary" onClick={goToPayment}>Renew</button>
                            <button class="button-secondary" onClick={checkOut}>Checkout</button>
                        </div>
                    </div>
                    <div class="card" onClick={goToTransport}>
                        <h2>Transport</h2>
              
                        <small>Book your ride to school</small>
                    </div>
                    <Link to="/UserPayment">
                        <div class="card">
                            <h2>Payment History</h2>
                            <small>Your invoices are here</small>
                        </div>
                    </Link>
                    <Link to="/profile">
                        <div class="card">
                            <h2>Profile</h2>
                            <small>update your personal information</small>
                        </div>
                    </Link>
                    <div class="card">
                        <h2>Service Box</h2>
                        <small>Drop us your complaints </small>
                    </div>
                </div>
            </body>
            {/* <div style={{ padding: '20px', backgroundImage: `url(${apartment.ApartmentRoomImage})`, backgroundSize: 'cover' }}>

                <div style={{ display: 'flex', justContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        <h2></h2>

                        <p>
                        </p>

                        {testCount && (
                            <>
                                <p style={{ color: 'Red' }}>Final Month Of Stay</p>
                            </>
                        )}
                        {informedMessage && (
                            <>
                                <p style={{ color: 'Yellow' }}> This months stay is on credit,and will be added to your next payment.  </p>
                            </>
                        )}
                    </div>
                    <div>
                        {showButtons && (
                            <>
                                <button onClick={goToPayment} >Renew</button>
                            </>
                        )}
                        <button onClick={checkOut}>Checkout</button>

                    </div>
                    <p>
                        {systemInitiated ?
                            "its overdue" : "there is nothing here"
                        }
                    </p>
                </div>
            </div> */}
        </div >
    );
}

function BookingList({ bookings }) {
    return (
        <div>
            <h2>My Bookings</h2>
            {bookings.map(booking => (
                <div key={booking.id}>
                    <p>{booking.location} - {booking.date}</p>
                </div>
            ))}
        </div>
    );
}

function PaymentList({ payments }) {
    return (
        <div>
            <h2>My Payments</h2>
            {payments.map(payment => (
                <div key={payment.id}>
                    <p>Amount: {payment.amount} - Status: {payment.status} - Date: {payment.date}</p>
                </div>
            ))}
        </div>
    );
}

function DropdownMenu() {
    return (
        <div style={{
            position: 'absolute',
            right: 4,
            top: "170px",
            backgroundColor: "#ffffff",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            borderRadius: "8px",
            width: "200px",
            zIndex: 1000

        }}>

            <Link style={{ display: "block", padding: "10px", textDecoration: "none", color: "#333" }} to="/profile">My Profile</Link>
            <Link style={{ display: "block", padding: "10px", textDecoration: "none", color: "#333" }} to="/favorite">Favorites</Link>
            <Link style={{ display: "block", padding: "10px", textDecoration: "none", color: "#333" }} to="/setting">Settings</Link>
            <Link style={{ display: "block", padding: "10px", textDecoration: "none", color: "#333" }} to="/">Logout</Link>



        </div>
    );
}
export default UserDashboard;
